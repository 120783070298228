import React from 'react'

// Runic
import useDirector from 'runic/hooks/useDirector'


const DynamicComponent = ({
  componentName,
  ...rest
}) => {
  const director = useDirector()
  const Component = director.components[componentName]

  if (!Component) {
    console.error('Component not found', componentName, director.components)
    return null
  }
  return <Component {...rest}/>
}

export default DynamicComponent
import React from 'react'


// Runic
import { Route, Routes, Navigate } from 'runic-aura/router'
import AuthenticationBase from 'runic-aura/apps/authentication/pages/AuthenticationBase'
import useRunic from 'runic-aura/hooks/useRunic'

import RunicTopErrorBoundary from '../components/RunicTopErrorBoundary'
import RunicTopBar from '../components/RunicTopBar'
import RunicHome from '../pages/RunicHome'
import RunicPrint from '../pages/RunicPrint'


const RunicIndex = ({
  uiConfig,
  runicConfig,
  homeUrl = 'home',
  homeComponent = RunicHome
}) => {

  const { isReady } = useRunic({uiConfig, runicConfig})

  const Home = homeComponent

  return (
    <RunicTopErrorBoundary>
      {/* <RenoaVersionNotification /> */}
      <RunicTopBar isReady={isReady} />
        {isReady && (
          <Routes>
            <Route path="/_print/*" element={<RunicPrint/>}/>
            <Route path="auth/*" element={<AuthenticationBase />}/>
            <Route path="/" element={<Navigate to={homeUrl} />}/>
            <Route path="*" element={<Home />}/>
          </Routes>
        )}
    </RunicTopErrorBoundary>
  )
}

export default RunicIndex

import React from 'react'


const useRunicActionCreator = (actionName, elementName, kind = 'RCR_ACTION') => {
  const runicAction = React.useCallback((payload, callbacks) => ({
    type: actionName,
    meta: {
      status: 'START',
      kind: kind,
      component: elementName,
      actionKind: null
    },
    payload,
    callbacks,
  }), [actionName, elementName])

  runicAction.actionType = actionName

  return runicAction
}

export default useRunicActionCreator
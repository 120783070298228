import React from 'react'

// Vendor
import { useField } from 'react-final-form'
import Select from 'react-select'
import { useTheme } from 'emotion-theming'
import { setIn, getIn } from 'final-form'

// Volcano
import { Box, Flex } from 'volcano'
import { useFormState, useForm } from 'react-final-form'

import RfTextField from './RfTextField'

const RfDropdownField = ({
  name,
  field,
  options,
}) => {
  const { input, meta } = useField(name, {
    validate: (value) => {
      let error
      if (field?.required && (
        value === undefined ||
        value === null ||
        value == ''
      )) {
        error = 'Gerekli'
      }
      return error
    }
  })
  const theme = useTheme()

  let finalOptions = options ? [...options] : []

  const formState = useFormState({
    subscription: {
      values: true
    }
  })

  if (field.options_source) {
    if (field.options_source.source == 'FORM') {
      const a = getIn(formState.values, field.options_source.key)

      a?.forEach(x => {
        x?.data?.forEach(y => {
          finalOptions.push({
            value: y,
            label: y
          })
        })
      })

      // finalOptions = getIn(formState.values, field.options_source.key).map(x => ({
      //   value: x,
      //   label: x
      // }))
      console.log(getIn(formState.values, field.options_source.key))
    }

  }

  if (field.hasOther) finalOptions.push(field.hasOther)

  return (
    <Box>
      <Select
      onChange={(props) => {
        field?.isMulti ? input.onChange(props?.map(x => x.value)) : input.onChange(props?.value)
      }}
      value={options?.find(x => x.value === input.value)}
      options={finalOptions}
      isMulti={field?.isMulti}
      placeholder={field.placeholder || 'Seç...'}
      // menuPlacement='top'
      styles={{
        control: (provided, state) => ({
          ...provided,
          borderColor: theme.colors.primary,
          paddingTop: 4,
          paddingBottom: 4,
        }),

        menu: (provided, state) => ({
          ...provided,
          zIndex: 9
        })
      }} />

      {field.hasOther && input.value === field.hasOther.value && (
        <RfTextField placeholder={field.hasOther.placeholder} name={`${name}${field.hasOther.label}`} field={{...field, kind: 'text'}}/>
      )}
    </Box>
  )
}

export default RfDropdownField
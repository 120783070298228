import React from 'react'

// Vendor
import * as yup from 'yup'
import dotProp from 'dot-prop'
import { useFormState } from 'react-final-form'

// Reverb
import { TextField, CheckboxField, DropdownField } from 'runic-aura/components/RunicField'
import Text from 'volcano/components/Text'

import ModelTypeahead from './ModelTypeahead'


const EntityFormField = ({
  entity,
  field,
  index
}) => {
  if (field.connected) {
    return <ConnectedEntityFormField field={field} index={index} entity={entity}/>
  } else {
    return <DefaultEntityFormField field={field} index={index} entity={entity}/>
  }
}

const DefaultEntityFormField = ({
  entity,
  field,
  index
}) => {
  let schema
  if (!field.optional) schema = yup.string().required()

  const focus = index === 0
  if (field.fieldKind == 'TYPEAHEAD') {
    return (
      <ModelTypeahead modelName={field.model} type={field.kind} name={field.name} label={<Text textCase='title' id={field.label} />} schema={schema} required={!field.optional} focus={focus} />
    )
  } else if (field.fieldKind == 'dropdown') {
    return (
      <DropdownField name={field.name} label={<Text textCase='title' id={field.label} />} schema={schema} required={!field.optional} {...field.componentProps} />
    )
  }

  return (
    <TextField type={field.kind} name={field.name} label={<Text textCase='title' id={field.label} />} schema={schema} required={!field.optional} focus={focus} />
  )

}

const ConnectedEntityFormField = ({
  entity,
  field,
  index
}) => {
  let schema
  if (!field.optional) schema = yup.string().required()

  const formState = useFormState({
    subscription: {
      values: true
    }
  })

  const values = formState.values

  let isReady = !field.dependsOn
  field.dependsOn?.forEach(name => {
    if (
      values[name] !== null && values[name] !== undefined
    ) {
      isReady = true
    }
  })

  if (!isReady) return null

  const params = {}
  if (field.params) {
    Object.entries(field.params).forEach(([key, value]) => {
      if (value.includes('@@r.form.')) {
        const keys = value.replace('@@r.form.', '')
        const resolvedValue = dotProp.get(values, keys)
        params[key] = resolvedValue
      } else if (value.includes('@@r.ctx.')) {
        const keys = value.replace('@@r.ctx.', '')
        const resolvedValue = dotProp.get(values._client.ctx, keys)
        console.log(keys, values, resolvedValue)
        params[key] = resolvedValue
      } else {
        params[key] = value
      }
    })
  }

  const focus = index === 0

  if (field.fieldKind == 'TYPEAHEAD') {
    return (
      <ModelTypeahead modelName={field.model} type={field.kind} name={field.name} label={<Text textCase='title' id={field.label} />} schema={schema} required={!field.optional} focus={focus} params={params}/>
    )
  } else if (field.fieldKind == 'dropdown') {
    return (
      <DropdownField name={field.name} label={<Text textCase='title' id={field.label} />} schema={schema} required={!field.optional} {...field.componentProps} />
    )
  }

  return (
    <TextField type={field.kind} name={field.name} label={<Text textCase='title' id={field.label} />} schema={schema} required={!field.optional} focus={focus} />
  )

}

export default EntityFormField
import React from 'react'

// Runic
import { Routes, Route } from 'runic-aura/router'
import { useRncActivate } from 'runic-aura/apps/uitree/hooks/tree'

import RxAccountSelect from '../components/RxAccountSelect'
import RxAccountHome from './RxAccountHome'


const RxAccountDomain = ({
  domain
}) => {
  useRncActivate('domain', domain)
  return (
    <Routes>
      <Route path={`select`} element={<RxAccountSelect domain={domain}/>}/>
      <Route path={`r/:rxAccountId/*`} element={<RxAccountHome domain={domain} />}/>
    </Routes>
  )
}

export default RxAccountDomain
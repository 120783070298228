import loadable from '@loadable/component'

const Dashboard = loadable(() => import('./pages/Dashboard'))
const KvkkDataEntry = loadable(() => import('./pages/KvkkDataEntry'))
const KvkkDataDefaults = loadable(() => import('./pages/KvkkDataDefaults'))
const KvkkAccount = loadable(() => import('./pages/KvkkAccount'))
const KvkkDataSource = loadable(() => import('./pages/KvkkDataSource'))
const KvkkDataProcessing = loadable(() => import('./pages/KvkkDataProcessing'))
const KvkkDataInventory = loadable(() => import('./pages/KvkkDataInventory'))
const KvkkDataVerbis = loadable(() => import('./pages/KvkkDataVerbis'))
const KvkkDataAccessMatrix = loadable(() => import('./pages/KvkkDataAccessMatrix'))
const KvkkDataTransferMatrix = loadable(() => import('./pages/KvkkDataTransferMatrix'))
const KvkkDataShareMatrix = loadable(() => import('./pages/KvkkDataShareMatrix'))
const KvkkDataPermissionMatrix = loadable(() => import('./pages/KvkkDataPermissionMatrix'))
const KvkkAccountSettings = loadable(() => import('./pages/KvkkAccountSettings'))
const DocumentInform = loadable(() => import('./pages/DocumentInform'))
const DocumentPermission = loadable(() => import('./pages/DocumentPermission'))
const DocumentPrint = loadable(() => import('./pages/DocumentPrint'))
const DocumentPersonelCommitment = loadable(() => import('./pages/DocumentPersonelCommitment'))
const DocumentProcessingPolicy = loadable(() => import('./pages/DocumentProcessingPolicy'))
const DocumentKvkkPolicy = loadable(() => import('./pages/DocumentKvkkPolicy'))
const DocumentKvkkSpecialPolicy = loadable(() => import('./pages/DocumentKvkkSpecialPolicy'))
const TaskManagement = loadable(() => import('./pages/TaskManagement'))
const KvkkAccountUser = loadable(() => import('./pages/KvkkAccountUser'))
const GeneralPrecautions = loadable(() => import('./pages/GeneralPrecautions'))
const EmbedSettings = loadable(() => import('./pages/EmbedSettings'))
const AccountInfo = loadable(() => import('./pages/AccountInfo'))
const DataManagement = loadable(() => import('./pages/DataManagement'))
const DocumentOwnerTransfer = loadable(() => import('./pages/DocumentOwnerTransfer'))
const DocumentProcessorTransfer = loadable(() => import('./pages/DocumentProcessorTransfer'))
const DocumentCookiePolicy = loadable(() => import('./pages/DocumentCookiePolicy'))
const DocumentKvkkStart = loadable(() => import('./pages/DocumentKvkkStart'))
const DocumentKvkkCommitee = loadable(() => import('./pages/DocumentKvkkCommitee'))
const DocumentGeneralReport = loadable(() => import('./pages/DocumentGeneralReport'))
const UserSettings = loadable(() => import('./pages/UserSettings'))
const TemplateManager = loadable(() => import('./pages/TemplateManager'))
const SetupAndCompliance = loadable(() => import('./pages/SetupAndCompliance'))
const Documents = loadable(() => import('./pages/Documents'))
const SetupAndSettings = loadable(() => import('./pages/SetupAndSettings'))

import RxAccountDomain from './pages/RxAccountDomain'
import RxAccountSidebarMenu from './components/RxAccountSidebarMenu'

export default {
  name: 'kvkkpro',
  components: {
    'KvkkDataEntry': {
      component: KvkkDataEntry
    },

    'EmbedSettings': {
      component: EmbedSettings
    },

    'SetupAndSettings': {
      component: SetupAndSettings
    },

    'KvkkDataDefaults': {
      component: KvkkDataDefaults
    },

    'KvkkAccount': {
      component: KvkkAccount
    },

    'KvkkDataSource': {
      component: KvkkDataSource
    },

    'KvkkDataProcessing': {
      component: KvkkDataProcessing
    },

    'KvkkDataInventory': {
      component: KvkkDataInventory
    },

    'KvkkDataVerbis': {
      component: KvkkDataVerbis
    },

    'KvkkDataAccessMatrix': {
      component: KvkkDataAccessMatrix
    },

    'KvkkDataTransferMatrix': {
      component: KvkkDataTransferMatrix
    },

    'Dashboard': {
      component: Dashboard
    },

    'KvkkAccountSettings': {
      component: KvkkAccountSettings
    },

    'KvkkDataPermissionMatrix': {
      component: KvkkDataPermissionMatrix
    },

    'DocumentInform': {
      component: DocumentInform
    },

    'DocumentPermission': {
      component: DocumentPermission
    },
    'DocumentPrint': {
      component: DocumentPrint
    },
    'DocumentPersonelCommitment': {
      component: DocumentPersonelCommitment
    },
    'DocumentProcessingPolicy': {
      component: DocumentProcessingPolicy
    },
    'DocumentKvkkPolicy': {
      component: DocumentKvkkPolicy
    },
    'DocumentKvkkSpecialPolicy': {
      component: DocumentKvkkSpecialPolicy
    },
    'TaskManagement': {
      component: TaskManagement
    },
    'KvkkAccountUser': {
      component: KvkkAccountUser
    },
    'GeneralPrecautions': {
      component: GeneralPrecautions
    },
    'AccountInfo': {
      component: AccountInfo
    },
    'DataManagement': {
      component: DataManagement
    },
    'DocumentOwnerTransfer': {
      component: DocumentOwnerTransfer
    },
    'DocumentProcessorTransfer': {
      component: DocumentProcessorTransfer
    },
    'DocumentCookiePolicy': {
      component: DocumentCookiePolicy
    },
    'DocumentKvkkStart': {
      component: DocumentKvkkStart
    },
    'DocumentKvkkCommitee': {
      component: DocumentKvkkCommitee
    },
    'DocumentGeneralReport': {
      component: DocumentGeneralReport
    },
    'UserSettings': {
      component: UserSettings
    },
    'TemplateManager': {
      component: TemplateManager
    },
    'KvkkDataShareMatrix': {
      component: KvkkDataShareMatrix
    },
    'SetupAndCompliance': {
      component: SetupAndCompliance
    },
    'Documents': {
      component: Documents
    },
  },
  directory: {
    runicAura: {
      domainHandlers: {
        RxAccount: RxAccountDomain
      },
      menuHandlers: {
        RxAccount: RxAccountSidebarMenu
      }
    }
  }
}
import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

import { useNavigate, useParams, Link } from 'runic-aura/router'
import authenticationActions from 'runic/systems/authentication/actions'
import { TextField, CheckboxField } from 'runic-aura/components/RunicField'
import Form, { useRunicForm, yup } from 'runic-aura/components/RunicForm'

// Volcano
import { Flex, Box } from 'volcano'
import Button from 'volcano/components/Button'
import Card from 'volcano/components/Card'


const AuthenticationReset = props => {
  const [phase, setPhase] = React.useState('CHECK')

  const dispatch = useDispatch()
  const { token } = useParams()

  React.useEffect(() => {
    dispatch(authenticationActions.checkResetToken({ token }, {
      success: (payload) => setPhase('FORM'),
      error: (payload) => setPhase('ERROR')
    }))
  }, [])

  if (phase == 'FORM') {
    return (<AuthenticationResetForm token={token}/>)
  } else {
    let message
    if (phase == 'CHECK') message = 'Kontrol ediliyor.'
    else if (phase == 'ERROR') message = 'Bu istek geçersiz veya süresi dolmuş.'

    return (
      <Flex alignItems='center' justifyContent='center'>
      <Box width={[1 / 2, 1 / 2, 1 / 2, 1 / 2, 1 / 4]} mt='200px' pr='30px' pt='25px'>
        <Card>
            <Box sx={{
              fontWeight: 'bold',
              mb: 3
            }}>
              Şifre Sıfırla
            </Box>
            {message}
            <Flex sx={{
              flexDirection: 'row-reverse',
              mt: 3
            }}>
              <Link to='../../login' tabIndex={5}>
              Girişe geri dön
              </Link>
            </Flex>
        </Card>
      </Box>
    </Flex>
    )
  }

}

const AuthenticationResetForm = ({
  token
}) => {
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated)
  const navigate = useNavigate()

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate(`/home`)
    }
  }, [isAuthenticated])

  const [formProps, isSubmitting] = useRunicForm(authenticationActions.resetPassword, {
    // action: (data, { setSubmitting }) => {
    //   return [
    //     loginData,
    //     // {
    //     //   success: () => navigate.push(`/home`),
    //     // }
    //   ]
    // }
  }, {
    initialValues: {
      token
    }
  })

  if (formProps.phase === 'DONE') {
    return (
      <Flex alignItems='center' justifyContent='center'>
      <Box width={[1 / 2, 1 / 2, 1 / 2, 1 / 2, 1 / 4]} mt='200px' pr='30px' pt='25px'>
        <Card>
          <Form {...formProps}>
            <Box sx={{
              fontWeight: 'bold',
              mb: 3
            }}>
              Şifremi Unuttum
            </Box>
            Şifren başarıyla değişti. <Link to='../../login'>Buradan</Link> giriş yapabilirsin.
            <Flex sx={{
              flexDirection: 'row-reverse',
              mt: 3
            }}>
              <Link to='../../login' tabIndex={5}>
              Girişe geri dön
              </Link>
            </Flex>
          </Form>
        </Card>
      </Box>
    </Flex>
    )
  }

  return (
    <Flex alignItems='center' justifyContent='center'>
      <Box width={[1 / 2, 1 / 2, 1 / 2, 1 / 2, 1 / 4]} mt='200px' pr='30px' pt='25px'>
        <Card>
          <Form {...formProps}>
            <Box sx={{
              fontWeight: 'bold',
              mb: 3
            }}>
              Şifremi Unuttum
            </Box>
            <TextField name="password" label='Şifre' type='password' tabIndex={2} required focus />
            <TextField name="passwordConfirm" label='Şifre (Tekrar)' type='password' tabIndex={2} required />
            <Button label='GÖNDER' fullWidth tabIndex={4} submit working={isSubmitting} />
            <Flex sx={{
              flexDirection: 'row-reverse',
              mt: 3
            }}>
              <Link to='../../login' tabIndex={5}>
              Girişe geri dön
              </Link>
            </Flex>
          </Form>
        </Card>
      </Box>
    </Flex>
  )
}

export default AuthenticationReset
import React from 'react'

// Vendor
import SVG from 'react-inlinesvg'

// Reverb
import { Flex, Box, createBoxAs, space, layout, color, border, t, system, useTheme } from '../index'


const fill = system({
  fill: {
    property: 'fill',
    scale: 'colors',
  },
})

const Svg = createBoxAs(
  SVG,
  space,
  layout,
  border,
  fill,
)


const Icon = React.forwardRef((props, ref) => {
  const { name, url, icon, color = 'currentColor', size, children, ...rest } = props
  const theme = useTheme()
  const baseAssetUrl = theme.config.baseAssetUrl
  const iconContent = (
    <Svg
      ref={ref}
      src={url || `${baseAssetUrl}/svg/${name || icon}.svg`}
      // style={{}}
      width={size || 16}
      height={size || 16}
      sx={{
        ...props.sx,
        verticalAlign: 'middle'
      }}
      fill={color}
      {...rest}
    >
    </Svg>
  )

  if (children) {
    return (
      <Flex alignItems='center'>
      {iconContent}
      {children}
    </Flex>
    )
  } else {
    return iconContent
  }
})

export default Icon
import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'
import URI from 'urijs'

// Volcano
import { ButtonIcon } from 'volcano/components/Button'


const ModelListViewExport = ({
  listViewInstance,
  filter,
  segment
}) => {
  console.log(listViewInstance, filter)
  const baseAPIUrl = useSelector(state => state.core.config.baseAPIUrl || '')
  const baseHeaders = {
    'X-RUNIC-PLATFORM': useSelector((state) => state.core.config.runicPlatform)
  }
  const rcTenantId = useSelector((state) => (state.core.config.isMultiTenant ? state.core.activeRcTenantId : 1))
  const downloadUrl = `${baseAPIUrl}/_api/runic/source/${listViewInstance.modelData.identifier}.ListExport`
  const url = new URI(downloadUrl)
  const opts = {
    // limit,
    // listViewInfo,
    // scopeInfo,
    fetchKey: 1,
    viewKey: 1,
    // ...internalState.opts,
    // startIndex,
    // viewIndex,
    segment,
    // endIndex,
    // ...props.opts
  }
  url.setQuery({filters: JSON.stringify(filter.locationFilters), ...baseHeaders, rcTenantId, opts: JSON.stringify(opts)})
  return (
  <a key='xlsx' href={url.toString()} download>
      <ButtonIcon iconName={'table'} variant='flat' />
    </a>
  )
}

export default ModelListViewExport
import React from 'react'

// Runic
import useDirector from 'runic/hooks/useDirector'

// RunicAura
import { Routes, Route, useMatch, useResolvedPath, useLocation } from 'runic-aura/router'
import { useRncIndexRedirect, useRncActivate } from 'runic-aura/apps/uitree/hooks/tree'

const RncStandardDomain = ({
  domain,
  zone,
}) => {
  const director = useDirector()
  const resolved = useResolvedPath(':itemKind/:unitName/*')
  const match = useMatch(resolved.pathname)
  useRncActivate('domain', domain)
  // useRncIndexRedirect(match, domain.path, domain.insideIndexPath)

  if (!match) return null

  const { itemKind, unitName } = match.params

  const item = domain.items.find(i => i.unitName === unitName)
  if (!item) return null

  const Handler = director.directory.runicAura.unitHandlers[itemKind]

  if (!Handler) return null

  // console.log(item, Handler)
  // console.log(resolved.pathname, match)

  return (
    <Routes>
    <Route path={':itemKind/:unitName/*'} element={<Handler domain={domain} item={item} />}/>
    </Routes>
  )
}

export default RncStandardDomain

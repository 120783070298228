import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Runic
import actionGroupActions from 'runic/systems/action/actions'
import { getActionGroup, getActionGroupWithFetchKey } from 'runic/systems/action/selectors'

// Volcano
import { Box, Flex } from 'volcano'
// import Icon from 'volcano/components/Icon'
import { ButtonIcon } from 'volcano/components/Button'


const ModelCreateButton = ({
  modelName,
  params
}) => {
  const [isOpen, isOpenSet] = React.useState(false)

  return (
    <Box>
      <ButtonIcon iconName='plus' color='primary' onClick={() => isOpenSet(v => !v)}/>
      {isOpen && <ModelCreateMenu modelName={modelName} params={params}/>}
    </Box>
  )
}

const ModelCreateMenu = ({
  modelName,
  params
}) => {
  const modelData = useSelector(state => state.model.models[modelName])
  const modelIdentifier = modelData?.identifier
  const dispatch = useDispatch()
  const actionGroupKind = 'Create'

  const fetchKey = params ? createFetchKey(...Object.values(params)) : null

  React.useEffect(() => {
    dispatch(actionGroupActions.rcrGetActionGroupsForModel({modelIdentifier, actionGroupKind, params, fetchKey}))
  }, [modelIdentifier])

  const [actionGroup, actions] = useSelector(state => {
    if (fetchKey) return getActionGroupWithFetchKey(state, modelIdentifier, actionGroupKind, null, fetchKey)
    else return getActionGroup(state, modelIdentifier, actionGroupKind, fetchKey)
  })

  console.log('**', actions)

  return null
}

export default ModelCreateButton
// Runic
import { createActions } from 'runic/core/redux'

const ACTIONS = {
  LOCAL: {
    TOGGLE_ENTITY: {},
    TOGGLE_ALL: {},
    CLEAR_SELECTION: {},
    RCR_CREATE_OR_REPLACE_MENU: {},
    RCR_DEACTIVATE_MENU: {},
    RCR_REGISTER_APP_MAP: {},
    RCR_ACTIVATE_APP: {},
    RCR_DEACTIVATE_APP: {},
    RCR_ENTITY_LIST_EDIT_BEGIN: {},
    RCR_ENTITY_LIST_EDIT_END: {},
    RCR_ENTITY_LIST_EDIT_SAVE: {},
    RCR_ENTITY_LIST_EDIT_CHANGE_ENTITY_ORDER: {},
    RCR_ENTITY_LIST_EDIT_REMOVE_ENTITY_AT_INDEX: {},
    RCR_ENTITY_LIST_EDIT_ADD_ENTITY_AT_INDEX: {},
    RCR_ENTITY_LIST_EDIT_UPDATE_ENTITY_AT_INDEX: {},
    RCR_SET_UI_SETTINGS: {},
    RCR_TOGGLE_UI_SETTINGS: {},
    START_WATCH: {},
    STOP_WATCH: {},
    UPDATE_WATCH: {},
  }
}

export default createActions(ACTIONS, 'ui')

import React from 'react'

// Vendor
import * as changeCase from 'change-case'

import { Link } from 'runic-aura/router'

// Volcano
import Icon from 'volcano/components/Icon'
import Text from 'volcano/components/Text'
import { styled, t, tt, tc, Box, Flex } from 'volcano'
import { props } from '@styled-system/should-forward-prop'



export const SidebarWrapper = styled.div`
  background: ${tt('runicSidebar.bg', 'colors.light4')};
  border-radius: 0 4px 4px 0;
  width: ${p => p.extended ? '220px' : '65px'};
  min-height: 20px;

  position: sticky;
  margin-top: 100px;

  padding-bottom: 5px;
  margin-right: -30px;
  overflow: hidden;
`

export const SidebarSeparator = styled.hr`
  margin: 10px auto;
  border: none;
  border-top: 1px solid ${tt('runicSidebar.separatorColor', null, '#E0E0E9')};
  width: ${p => p.extended ? '90%' : '40%'};
`


export const SidebarGroupWrapper = styled.div`
  margin-bottom: 20px;
`

export const SidebarGroupHeader = styled.div`
  font-weight: ${t('fontWeights.bold')};
  font-size:  ${t('fontSizes.s')};
  color: ${tt('runicSidebar.groupHeader', 'colors.primary')};
  margin-left: 20px;
`

// export const SidebarItemLink = styled.div`
//   text-align: ${p => p.extended ? 'left' : 'center'};
//   ${p => p.extended && 'padding-left: 13px;'}
//   display: block;
//   width: 100%;
//   margin: 10px 0;

//   color: ${tt('runicSidebar.linkColor', 'dark8')};
//   ${p => p.distinct && `color: ${tt('runicSidebar.linkColorDistinct', 'primary')(p)};`}

//   ${p => p.active && `border-left: 3px solid ${tt('runicSidebar.markColor', 'primary')(p)}; border-radius: 0px 4px 4px 0; color: ${tt('runicSidebar.linkColorActive', 'dark')(p)};`}
//   box-sizing: border-box;

//   transition: all ease 0.3s;

//   align-items: center;
//   display: flex;
//   margin-top: 15px;
// `

import {useTheme} from 'volcano'

export const SidebarItemLink = ({
  sx,
  extended,
  distinct,
  active,
  ...props
}) => {
  const theme = useTheme()
  let finalSx = {
    textAlign: extended ? 'left': 'center',
    pl: extended ? 13 : 0,
    my: 3,
    color: distinct ? theme.runicSidebar.linkColorDistinct : theme.runicSidebar.linkColor,
    transition: 'all 0.3s ease',
    alignItems: 'center',
    mt: 15
  }

  if (active) {
    finalSx = {
      ...finalSx,
      borderLeft: '1px solid #FFF',
      borderLeftColor: theme.runicSidebar.markColor,
      borderRadius: 1,
      color: theme.runicSidebar.linkColorActive,
    }
  }
  return (
    <Flex
    sx={{
      ...finalSx,
      ...theme.runicSidebar?.SidebarItemLink?.sx({extended, distinct, active}),
      ...sx
    }}
    {...props}
  />
  )
}

const SidebarItemText = styled.span`
  ${p => p.extended ? 'font-weight: 500' : 'font-weight: bold'};
  font-size: ${p => p.extended ? t('fontSizes.s') : t('fontSizes.xs')};

  text-overflow: ellipsis;
  overflow: hidden;
  ${p => !p.extended && 'display: block;'}
  ${p => p.extended && 'display: inline-block;'}

  width: 80%;

  transition: all 0.2s ease;

  &:hover {
    color: ${tt('runicSidebar.linkColorHover', 'primary')};
  }
`

export const SidebarMenuTextWrapper = styled.div`
  margin: 10px 20px;
  display: block;
  width: calc(100% - 40px);
  color: ${tt('runicSidebar.textColor', 'dark')};
`

export const SidebarItem = ({ icon, to, name, label, extended, ...rest }) => {
  const content = (
    <SidebarItemLink {...rest} extended={extended}>
      {extended ? (
        <>
          <Icon name={icon} mr={2} />
          <SidebarItemText extended={extended}><Text textCase='upper' id={label || `label.${changeCase.snakeCase(name)}`} /></SidebarItemText>
        </>
      ) : (
          <>
            <div><Icon name={icon} mr={2} /></div>
            <SidebarItemText><Text textCase='upper' id={label || `label.${changeCase.snakeCase(name)}`} /></SidebarItemText>
          </>
        )}
    </SidebarItemLink>
  )

  return to ? (
    <Link to={to}>
      {content}
    </Link>
  ) : (
      content
    )
}

export const RncMenuItem = props => {
  const { unit, activeUnit, uiConfig, menuItem, basePath } = props
  if (!unit) {
    console.log('UNIT_NOT_FOUND', menuItem.unitName)
    return null
  }

  const active = activeUnit && (unit.name == activeUnit.unitName)

  const unitKind = unit.kind || (unit.modelName ? 'r1' : 'r2')
  const path = `${basePath}${unitKind}/${menuItem.path}/`

  // console.log(path)
  // console.log(basePath, unitKind, menuItem.path)
  return (
    <SidebarItem icon={menuItem.icon || unit.icon} name={unit.displayName} active={active} to={path} extended={uiConfig.extended} />
  )
}
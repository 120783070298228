import React from 'react'

// Vendor
import { Provider } from 'react-redux'

// Runic
import { RunicContext } from 'runic/context'

import core from 'runic/systems/core'
import authentication from 'runic/systems/authentication'
import model from 'runic/systems/model'
import entity from 'runic/systems/entity'
import action from 'runic/systems/action'
import ui from 'runic/systems/ui'
import upload from 'runic/systems/upload'

const defaultSystems = [
  core,
  authentication,
  model,
  entity,
  action,
  ui,
  upload
]

import useRunicApp from './hooks/useRunicApp'

export const rncuiConfig = {
  component: {
    RenoaSidebar: {
      extended: true
    }
  }
}

const RunicBaseApp = ({
  apps,
  systems = [],
  storeCreator,
  rConfig,
  children
}) => {
  const { isReady, store, director } = useRunicApp(({
    apps,
    systems: [...defaultSystems, ...systems],
    storeCreator,
    rConfig
  }))

  if (!isReady) return null

  return (
    <RunicContext.Provider value={director}>
      <Provider store={store}>
        {children}
      </Provider>
    </RunicContext.Provider>
  )

}

export default RunicBaseApp

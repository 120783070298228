import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Runic
import useDirector from 'runic/hooks/useDirector'
import { Routes, Route, useMatch, useResolvedPath, useParams } from 'runic-aura/router'
import runicAuraActions from 'runic-aura/systems/runic-aura/actions'


const RxAccountHome = ({
  domain
}) => {
  const dispatch = useDispatch()
  const params = useParams()

  React.useEffect(() => {
    dispatch(runicAuraActions.rncModifyDirectory({activeAccountId: params.rxAccountId}))
    return () => dispatch(runicAuraActions.rncModifyDirectory({activeAccountId: null}))
  }, [params.rxAccountId])

  const director = useDirector()
  const resolved = useResolvedPath(':itemKind/:unitName/*')
  const match = useMatch(resolved.pathname)

  if (!match) return null

  const { itemKind, unitName } = match.params

  const item = domain.items.find(i => i.unitName === unitName)
  if (!item) return null

  const Handler = director.directory.runicAura.unitHandlers[itemKind]

  if (!Handler) return null

  // console.log(item, Handler)
  // console.log(resolved.pathname, match)

  return (
    <Routes>
      <Route path={':itemKind/:unitName/*'} element={<Handler domain={domain} item={item} />}/>
    </Routes>
  )
}

export default RxAccountHome
import React from 'react'

// Vendor
import { useSelector } from 'react-redux'

// Rncui
import { getColumnsForModel, getRenderDataForField, getLabelForField } from '../util'
import EntityActionGroupMenu from './EntityActionGroupMenu'
import { RelatedEntityField, EntityField } from './ModelHelpers'

// Reverb
import { Flex, Box } from 'volcano'
import Text from 'volcano/components/Text'
import Icon from 'volcano/components/Icon'
import Card from 'volcano/components/Card'

const Content = props => <div><Card css={{ boxShadow: "0 1px 3px 0 rgba(21,27,38,.15)" }}>{props.children}</Card></div>

const FieldHeader = props => {
  const { modelDataMap, column } = props
  const [targetFieldData, columnPath, searchPath, modelList] = React.useMemo(() => getRenderDataForField(modelDataMap, column.columnFieldData, column.modelName, column.fieldAttrPath), [modelDataMap, column.columnFieldData, column.modelName, column.fieldAttrPath])
  const [label, topLabel, preLabel] = React.useMemo(() => getLabelForField(targetFieldData, columnPath, modelList, column.labelRenderer), [modelDataMap, targetFieldData, columnPath, column.labelRenderer])
  return label
}

const ModelDetailCard = ({
  entity,
  modelData,
  noWrapper,
  view,
  entityProperties,
  label: labelFromProps,
  labelText,
  direction = 'horizontal',
  include,
  exclude,
  noCard,
  rightMenuItems,
  bottomRenderer,
  fieldKind = 'primary',
  header: headerFromProps,
  hideHeaderBox,
  extraActions,
  headerText,
  showMenu = true
}) => {

  const modelName = modelData.name

  const modelDataMap = useSelector(state => state.model.models)

  console.log('view', view)

  const fields = view[`${fieldKind}_fields`] || []

  const entityFieldsToRender = React.useMemo(() => {
    if (!fields) return null
    const initialEntityFieldsToRender = entityProperties || fields
    const includedFieldsToRender = include ? initialEntityFieldsToRender.concat(include) : initialEntityFieldsToRender
    return exclude ? includedFieldsToRender.filter(name => !exclude.includes(name)) : includedFieldsToRender
  }, [view, include, exclude])

  if (!entityFieldsToRender) return null

  const columns = getColumnsForModel(entityFieldsToRender, modelData)

  const content = columns.map((column, dx) => {
    let content
    if (column.columnFieldData.is_relationship) content = <RelatedEntityField key={dx} entity={entity} columnAttrPath={column.fieldAttrPath} columnFieldData={column.columnFieldData} column={column} modelDataMap={modelDataMap} />
    else if (column.columnFieldData.is_reverse_relationship) content = entity[column.fieldAttrPath].map(x => x.name).join(', ')
    else content = <EntityField key={dx} entity={entity} columnAttrPath={column.fieldAttrPath} columnFieldData={column.columnFieldData} column={column} />

    const header = <FieldHeader modelDataMap={modelDataMap} column={column} />

    const boxProps = {
      key: dx,
      cst: true,
    }
    if (direction == 'horizontal') {
      boxProps.width = 1 / 6
    } else if (direction == 'vertical') {
      boxProps.width = 1
      boxProps.mb = 20
    }

    return (
      <Box {...boxProps}>
        <Text block fontSize='s' color='dark8'>
          {header}
        </Text>

        <Text block mt={1} wrap>
          {content}
        </Text>
      </Box>
    )
  })

  const label = labelFromProps ? `field.${labelFromProps}` : labelText || modelData.displayName
  const header = headerText || entity.name

  const menu = showMenu && (
    <EntityActionGroupMenu modelName={modelName} entity={entity} extraActions={extraActions}/>
  )

  const allContent = (
    <Content label={label} header={headerFromProps} noCard={noCard}>
      {!hideHeaderBox && (
        <Flex mx={0} px={0} mb={20} justifyContent='space-between'>
          <Box width={5 / 6} sx={{
            alignItems: 'center',
            display: 'flex'
          }}>
            <Icon name={modelData.icon || 'asterisk'} mr={2}/>
            <Text fontWeight='bold' h5>
                {header}
              </Text>
          </Box>

          <Box>
            {menu}
            {rightMenuItems}
          </Box>
        </Flex>
      )}

      {direction == 'horizontal' && (
        <Flex my={0} pb={10}>
          {content}
        </Flex>
      )}

      {direction == 'vertical' && (
        <Flex my={0} flexDirection='column'>
          {content}
        </Flex>
      )}

      {bottomRenderer && bottomRenderer(entity)}
    </Content>
  )

  if (noWrapper) return allContent

  return (
    <Flex>
      <Box w={1}>
        {allContent}
      </Box>
    </Flex>
  )
}

export default ModelDetailCard

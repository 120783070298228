import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Volcano
import { Flex, Box } from 'volcano'
import Button from 'volcano/components/Button'
import Card from 'volcano/components/Card'

// RunicAura
import { TextField, CheckboxField } from 'runic-aura/components/RunicField'
import Form, { useRunicForm, yup } from 'runic-aura/components/RunicForm'
import { useNavigate, useParams, Link } from 'runic-aura/router'

// Runic
import authenticationActions from 'runic/systems/authentication/actions'
import useDirector from 'runic/hooks/useDirector'


const AuthenticationLogin = props => {
  const params = useParams()
  const rcTenantCode = params?.rcTenantCode
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated)
  const navigate = useNavigate()
  const director = useDirector()

  const [formProps, isSubmitting] = useRunicForm(authenticationActions.rcrLogin, {
    action: (data, { setSubmitting }) => {
      const loginData = { ...data }
      if (!loginData.rcTenantCode) {
        if (props.rcTenantId) loginData.rcTenantId = parseInt(rcTenantId)
        else if (rcTenantCode) loginData.rcTenantCode = rcTenantCode
        else { loginData.rcTenantId = 1 }
      }
      return [
        loginData,
        // {
        //   success: () => navigate.push(`/home`),
        // }
      ]
    }
  })

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate(`/home`)
    }
  }, [isAuthenticated])

  return (
    <Flex alignItems='center' justifyContent='center'>
      <Box width={[1 / 2, 1 / 2, 1 / 2, 1 / 2, 1 / 4]} mt='200px' pr='30px' pt='25px'>
        <Card>
          <Form {...formProps}>
            {director.rConfig.logoPath && <Box sx={{ textAlign: 'center', mb: 3, mt: 3 }}><Box as='img' src={director.rConfig.logoPath} sx={{ width: '80%', mx: 'auto' }} /></Box>}
            {director.rConfig.isMultiTenant && !rcTenantCode && <TextField name="rcTenantCode" label='Hesap' tabIndex={1} required />}
            <TextField name="email" label='E-Posta' tabIndex={1} schema={yup.string().min(4).required()} required focus={!director.rConfig.multiTenant} />
            <TextField name="password" label='Şifre' type='password' tabIndex={2} required />
            <CheckboxField name='rememberMe' label='Beni hatırla' tabIndex={3} />
            <Button label='GİRİŞ' fullWidth tabIndex={4} submit working={isSubmitting} />
            <Flex sx={{
              flexDirection: 'row-reverse',
              mt: 3
            }}>
              <Link to='../forgot' tabIndex={5}>
              Şifremi Unuttum
              </Link>
            </Flex>
          </Form>
        </Card>
      </Box>
    </Flex>
  )
}

export default AuthenticationLogin

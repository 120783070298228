import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'
import Button, { ButtonIcon } from 'volcano/components/Button'

// Runic
import { Menu, MenuItem } from 'volcano/components/Menu'

import RfField from 'runic-form/components/RfField'
import Form, { useRunicForm, FormError } from 'runic-form/components/RfForm'
import useRunicActionCreator from 'runic/hooks/useRunicActionCreator'


const RfSingleAction = ({
  iconName = 'plus',
  iconColor = 'primary',
  actionKey,
  onSuccess,
  onError,
  fields,
  initialValues,
  buttonText = 'Oluştur',
  header,
  formOnly,
  onCancel,
  errorMap
}) => {
  const ref = React.useRef()
  const content = <RfSingleActionForm onSuccess={onSuccess} onError={onError} menuRef={ref} actionKey={actionKey} fields={fields} initialValues={initialValues} buttonText={buttonText} header={header} onCancel={onCancel} errorMap={errorMap}/>
  if (formOnly) {
    return content
  }
  return (
    <Menu anchor={<ButtonIcon iconName={iconName} color={iconColor} />} placement='bottom-start' ref={ref}>
      {content}
    </Menu>
  )
}

const RfSingleActionForm = ({
  onSuccess,
  onError,
  menuRef,
  actionKey,
  fields,
  initialValues,
  header,
  buttonText,
  cancelButtonText = 'İPTAL',
  onCancel,
  errorMap
}) => {
  const [elementName, actionName] = actionKey.split('.')
  const action = useRunicActionCreator(actionName, elementName)
  const [formProps, isSubmitting] = useRunicForm(action, {
    action: (data) => ([{ ...data },{
      success: (response) => {
        onSuccess?.(response)
        menuRef?.current?.close()
      },
      error: (response) => {
        onError?.(response)
      }
    }])
  }, {
    initialValues
  })

  return (
    <Box sx={{
      background: '#FFF',
      p: 3,
      minWidth: 200
    }}>
      <Box sx={{
        fontWeight: 'bold',
        mb: 2
      }}>
        {header}
      </Box>
      <Form {...formProps}>
        {fields.map((f, dx) => {
          return (
            <Box key={dx}>
              <RfField field={f} autoFocus={dx === 0} />
            </Box>
          )
        })}
        <FormError error={formProps.error} errorMap={errorMap}/>
        <Button label={buttonText} fullWidth submit working={isSubmitting} />
        {onCancel && (
          <Button label={cancelButtonText} fullWidth onClick={onCancel} sx={{
            mt: 2,
            bg: '#FFF',
            color: 'primary',
            border: '1px solid #FFF',
            borderColor: 'primary'
          }} />
        )}
      </Form>
    </Box>
  )
}

export default RfSingleAction
import React from 'react'

// Vendor
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import * as changeCase from 'volcano/util/text'

// Runic
import { useModelDataFromProps, getListView, getListViewV2 } from 'runic/util/model'

import { getVersion, getModelData, getEntityMetadata } from 'runic/systems/model/selectors'
import { doSourceQuery, getEntityFromSource, getEntityListFromSource } from 'runic/systems/entity/actions'

import modelActions from 'runic/systems/model/actions'

import { dataFetchReducer, useInterval } from './util'
import useFetchKey from './useFetchKey'

export { default as useLocationFilter } from './useLocationFilter'

const useListViewSelector = (modelName, viewKey, fetchKey, lastUsedFetchKey) => (
  useSelector(state => {
    const modelState = state.entity[changeCase.camel(modelName)]
    if (!modelState) return null
    const viewName = modelState.viewKeyMatch[viewKey]
    const view = modelState.view[viewName]
    let usedFetchKey = fetchKey
    let list = modelState.list[fetchKey]
    if (!list) {
      usedFetchKey = lastUsedFetchKey
      list = modelState.list[lastUsedFetchKey]
    }

    return {
      view,
      list,
      modelName,
      fetchKey: usedFetchKey
    }
  }, shallowEqual)
)


const useListSourceQuery = (modelIdentifier, viewKey, fetchKey, {limit, onSuccessFetch, refreshInterval, filters, startIndex, endIndex, viewIndex, segment}) => {
  const didCancel = React.useRef(false)
  const dispatch = useDispatch()

  const [component, modelName] = modelIdentifier.split('.')

  const [internalState, internalDispatch] = React.useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    isReady: false,
    key: 0
  })

  const refresh = React.useCallback((payload = {}) => internalDispatch({ kind: 'REFRESH', payload }), [])

  const version = useSelector(state => getVersion(state, changeCase.snake(modelName).toUpperCase()), shallowEqual)

  const refreshIntervalInSeconds = refreshInterval
  const refreshIntervalToUse = refreshIntervalInSeconds && (refreshIntervalInSeconds > 5 ? refreshIntervalInSeconds * 1000 : 5 * 1000)
  useInterval(refresh, refreshIntervalToUse)

  React.useEffect(() => {
    internalDispatch({ kind: 'FETCH_INIT' })
    didCancel.current = false

    const archive = modelName
    const search = 'List'
    const actionName = `GET_LIST_${changeCase.snake(modelName).toUpperCase()}`

    const filterPayload = {
      ...filters,
      ...internalState.filters,
      // ...locationFilters,
      // ...listViewFilters
    }

    const opts = {
      limit,
      // listViewInfo,
      // scopeInfo,
      fetchKey,
      viewKey,
      ...internalState.opts,
      // startIndex,
      viewIndex,
      segment,
      // endIndex,
      // ...props.opts
    }

    // if (props.useContextVars) opts.contextVars = contextVars

    const payload = {
      filters: filterPayload,
      opts,
      // ...props.params,
      '|version': 2
    }

    const callbacks = {
      afterRequest: () => { if (didCancel.current) return false },
      success: () => {
        internalDispatch({ kind: 'FETCH_SUCCESS' })
        onSuccessFetch && onSuccessFetch()
      },
      error: (error) => {
        internalDispatch({ kind: 'FETCH_FAILURE', payload: error })
      },
    }

    dispatch(doSourceQuery(component, archive, search, actionName, payload, callbacks))

    return () => {
      didCancel.current = true
    }
  }, [modelIdentifier, fetchKey, version, internalState.key, startIndex, endIndex, viewIndex])
  // [modelIdentifier, fetchKey, version, internalState.key, locationFilters, props.useContextVars && contextVars, fetchKey]

  React.useEffect(() => {
    dispatch(modelActions.rcrModelStartWatch({name: modelName}))
    return () => {
      dispatch(modelActions.rcrModelStopWatch({name: modelName}))
    }
  }, [modelName])

  return [refresh, internalState]
}

export const useListView = (
  modelName,
  options = {},
) => {
  const {
    segment,
    filters = {},
    limit = 50,
    onSuccessFetch,
    refreshInterval,
    initialDisplayState = {mode: 'table'},
  } = options
  const {startIndex, endIndex, dx, ...processedFilters} = filters

  const viewKey = useFetchKey(segment)
  const fetchKey = useFetchKey(segment, processedFilters)
  const lastUsedFetchKeyRef = React.useRef(fetchKey)

  const modelData = useModelDataFromProps(modelName)
  const listView = useListViewSelector(modelName, viewKey, fetchKey, lastUsedFetchKeyRef.current)
  if (listView && listView.fetchKey && lastUsedFetchKeyRef.current != listView.fetchKey) lastUsedFetchKeyRef.current = listView.fetchKey

  const [ refresh, status ] = useListSourceQuery(modelData.identifier, viewKey, fetchKey, {limit, onSuccessFetch, refreshInterval, filters: processedFilters, startIndex, endIndex, viewIndex: dx, segment})

  const [displayState, setDisplayState] = React.useState(initialDisplayState)
  const setMode = React.useCallback(newMode => setDisplayState(prev => ({...prev, mode: newMode})))

  const instance = React.useMemo(() => ({
    listView,
    modelData,
    refresh,
    status,
    displayState,
    setMode,
    fetchKey,
  }), [fetchKey, status, displayState])

  return instance
}

const useDetailSourceQuery = (modelIdentifier, viewName, fieldName, value, fieldPath, refreshInterval, options) => {
  const didCancel = React.useRef(false)
  const dispatch = useDispatch()

  const [component, modelName] = modelIdentifier.split('.')

  const [internalState, internalDispatch] = React.useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    isReady: false,
    key: 0
  })

  const fetchKey = useFetchKey(modelName, fieldName, value, fieldPath, options, internalState.key)

  const filters = {
    viewName,
    fieldName,
    value,
    fieldPath,
    fetchKey,
    options: options ? JSON.stringify(options) : undefined,
    '|version': 2,
  }

  const callbacks = React.useMemo(() => ({
    success: () => internalDispatch({kind: 'FETCH_SUCCESS'}),
    error: () => internalDispatch({kind: 'FETCH_FAILURE'}),
  }), [])
  const searchName = 'Detail'
  const sourceName = null

  const refresh = React.useCallback(() => internalDispatch({ kind: 'REFRESH' }), [])

  const refreshIntervalInSeconds = refreshInterval
  const refreshIntervalToUse = refreshIntervalInSeconds && (refreshIntervalInSeconds > 5 ? refreshIntervalInSeconds * 1000 : 5 * 1000)
  useInterval(refresh, refreshIntervalToUse)

  React.useEffect(() => {
    console.log('ueu')
    internalDispatch({kind: 'FETCH_INIT'})
    didCancel.current = false
    dispatch(getEntityFromSource(component, modelName, filters, callbacks, searchName, sourceName))
    return () => {
      didCancel.current = true
    }
  }, [fetchKey])

  return [refresh, internalState, fetchKey]
}

export const useDetailView = ({
  modelName,
  value,
  fieldName = 'id',
  viewName = 'r_default_model_detail',
  fieldPath,
  refreshInterval,
  options
}) => {

  const modelData = useSelector(state => state.model.models[modelName])
  const [refresh, status, fetchKey] = useDetailSourceQuery(modelData.identifier, viewName, fieldName, value, fieldPath, refreshInterval, options)

  let selector
  if (fieldName === 'id' && !fieldPath && !options?.query) selector = state => state.entity[changeCase.camel(modelName)].entity[value]
  else selector = state => state.entity[changeCase.camel(modelName)].entity[state.entity[changeCase.camel(modelName)].entityKeyMatch[fetchKey]]
  const entity = useSelector(selector)

  const view = useSelector(state => state.entity[changeCase.camel(modelName)].view[state.entity[changeCase.camel(modelName)].viewKeyMatch[fetchKey]])

  const instance = {
    entity,
    modelData,
    view,
    status,
    refresh,
    fetchKey,
  }

  return instance
}
import React from 'react'

// Vendor
import loadable from '@loadable/component'

// Runic
import { Route, Routes, useParams } from 'runic-aura/router'
import AuthenticationCheck from 'runic-aura/apps/authentication/components/AuthenticationCheck'
import useDirector from 'runic/hooks/useDirector'

import RunicTenantSelector from './RunicTenantSelector'
import RunicTenantIndex from './RunicTenantIndex'

const RunicSocketManager = loadable(() => import('../components/RunicSocketManager'))


const RunicHome = ({
  TenantIndex = RunicTenantIndex,
  beforeRoutes,
  afterRoutes
}) => {
  const director = useDirector()
  const hasSocket = director.rConfig.socket?.enabled

  return (
    <AuthenticationCheck>
      {beforeRoutes}
      {hasSocket && <RunicSocketManager/>}
      <Routes>
        <Route path="/home" element={<RunicTenantSelector />}/>
        <Route path="/:rcTenantId/*" element={<TenantIndex />}/>
      </Routes>
      {afterRoutes}
    </AuthenticationCheck>
  )
}

export default RunicHome

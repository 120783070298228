import React from 'react'

// Vendor - Function
import styled from '@emotion/styled'
import dayjs from 'dayjs'

// FIXME
// import 'dayjs/locale/tr'
// dayjs.locale('tr')


class DateTime extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: this.getDateFromProps(props)
    }
  }

  componentDidMount() {
    this._refreshInterval = setInterval(this.refresh, 1000 * 60)
  }

  componentWillUnmount() {
    clearInterval(this._refreshInterval)
  }

  refresh = () => {
    this.forceUpdate()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) this.setState({data: this.getDateFromProps(this.props)})
  }

  getDateFromProps(props) {
    const { data } = props
    // FIXME: tz
    return data ? dayjs(data).add(3, 'hours') : null
  }

  getDateToCompare() {
    const { since } = this.props

    let dtToCompare
    if (since == 'now') dtToCompare = dayjs()
    else dtToCompare = dayjs(since)

    return dtToCompare
  }

  getDateToCompareForUntil() {
    const { until } = this.props

    let dtToCompare
    if (until == 'now') dtToCompare = dayjs()
    else dtToCompare = dayjs(until)

    return dtToCompare
  }

  renderWithFormat() {
    const { time, since } = this.props
    const { data } = this.state

    const baseFormat = 'DD.MM.YYYY'

    let format
    if (time) format = `${baseFormat} H:mm:ss`
    else format = baseFormat

    return (
      <span>{data.format(format)}</span>
    )
  }

  renderWithSince() {
    const { time, since, diffKind } = this.props
    const { data } = this.state

    const dateToCompare = this.getDateToCompare()

    let diff, kind

    if (diffKind == 'smart') {
      const diffInSeconds = dateToCompare.diff(data, 'seconds')
      const secondsFor1Minute = 60
      const secondsFor1Hour = secondsFor1Minute * 60
      const secondsFor1Day = secondsFor1Hour * 24
      const secondsFor1Month = secondsFor1Day * 30
      if (diffInSeconds > secondsFor1Month) { diff = dateToCompare.diff(data, 'months'); kind = 'ay'; }
      else if (diffInSeconds > secondsFor1Day) { diff = dateToCompare.diff(data, 'days'); kind = 'gün'; }
      else if (diffInSeconds > secondsFor1Hour) { diff = dateToCompare.diff(data, 'hours'); kind = 'saat'; }
      else if (diffInSeconds > secondsFor1Minute) { diff = dateToCompare.diff(data, 'minutes'); kind = 'dk.'; }
      else { diff = diffInSeconds; kind = 'sn.'; }
    } else {
      diff = dateToCompare.diff(data, diffKind)
      if (diffKind == 'minutes') kind = 'dk.'
      else if (diffKind == 'days') kind = 'gün'
      else if (diffKind == 'hours') kind = 'saat'
    }

    return (
      <span>{diff} {kind} önce</span>
    )
  }

  renderWithUntil() {
    const { time, since, diffKind } = this.props
    const { data } = this.state

    const dateToCompare = this.getDateToCompareForUntil()

    let diff, kind

    if (diffKind == 'smart') {
      const diffInSeconds = data.diff(dateToCompare, 'seconds')
      const secondsFor1Minute = 60
      const secondsFor1Hour = secondsFor1Minute * 60
      const secondsFor1Day = secondsFor1Hour * 24
      const secondsFor1Month = secondsFor1Day * 30
      if (diffInSeconds > secondsFor1Month) { diff = data.diff(dateToCompare, 'months'); kind = 'ay'; }
      else if (diffInSeconds > secondsFor1Day) { diff = data.diff(dateToCompare, 'days'); kind = 'gün'; }
      else if (diffInSeconds > secondsFor1Hour) { diff = data.diff(dateToCompare, 'hours'); kind = 'saat'; }
      else if (diffInSeconds > secondsFor1Minute) { diff = data.diff(dateToCompare, 'minutes'); kind = 'dk.'; }
      else { diff = diffInSeconds; kind = 'sn.'; }
    } else {
      diff = data.diff(dateToCompare, diffKind)
      if (diffKind == 'minutes') kind = 'dk.'
      else if (diffKind == 'days') kind = 'gün'
      else if (diffKind == 'hours') kind = 'saat'
    }

    return (
      <span>{diff} {kind} sonra</span>
    )
  }

  render() {
    const { time, since, until } = this.props
    const { data } = this.state
    if (!data) return null

    if (since) return this.renderWithSince()
    if (until) return this.renderWithUntil()
    else return this.renderWithFormat()
  }
}

DateTime.defaultProps = {
  diffKind: 'smart'
}

export default DateTime

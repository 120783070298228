import React from 'react'

// Vendor
import { useDispatch } from 'react-redux'

// Volcano
import { Flex, Box } from 'volcano'

// Runic
import useDirector from 'runic/hooks/useDirector'

// RunicAura
import { Route, Routes, useParams } from 'runic-aura/router'
import RunicSidebarZoneDefault from '../components/RunicSidebarZoneDefault'

import runicAuraActions from 'runic-aura/systems/runic-aura/actions'


const RunicZone = ({
  zone,
  baseUrl
}) => {
  const director = useDirector()
  const dispatch = useDispatch()
  const params = useParams()

  React.useEffect(() => {
    dispatch(runicAuraActions.rncActivateZone({ zoneName: zone.name, zoneParams: params }))
  }, [])

  if (zone.handler) {
    const ZoneHandler = director.directory.runicAura?.zoneHandlers?.[zone.handler]
    if (ZoneHandler) return <ZoneHandler baseUrl={baseUrl} zone={zone} zoneParams={params}/>
  }

  return (
    <Flex>
      <Box>
        <RunicSidebarZoneDefault baseUrl={baseUrl} />
      </Box>
      <Box mt={50} ml={30} width={1}>
        <Routes>
          {Object.values(zone.domains).map((domain, dx) => {
            const DomainHandler = director.directory.runicAura?.domainHandlers[domain.kind]
            const path = `${domain.path}/*`

            if (!DomainHandler) {
              console.log('Domain handler not found for domain', domain)
              return null
            }

            return (
              <Route key={dx} path={path} element={<DomainHandler zone={zone} domain={domain} />}/>
            )
          })}
        </Routes>
      </Box>
    </Flex>
  )
}

export default React.memo(RunicZone)

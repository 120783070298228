import React from 'react'

// Vendor
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList as List } from 'react-window'

// Reverb
import { styled, t, tt, tc } from 'volcano'


export const HeaderColumnWrapper = styled.div`
  height: 40px;
`
export const HeaderColumnTopLabel = styled.div`
  height: 8px;
  padding-bottom: 3px;

  color: ${tc('primary')};

  font-family: ${t('headerFontFamily')};
  font-size:  ${t('fontSizes.s')};
`

export const HeaderColumnLabel = styled.div`
  color: ${tc('primary')};

  font-family: ${t('headerFontFamily')};
  font-size:  ${t('fontSizes.base')};
  font-weight: ${t('fontWeights.bold')};

  display: flex;
`

export const HeaderColumnLabelText = styled.span`
  display: inline-block;
  text-overflow: ellipsis;
  width: calc(100% - 12px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const RowWrapper = styled.div`
  display: flex;

  &:hover {
    background-color: ${tc('light7')};
  }

  overflow: hidden;
`

const CellWrapper_ = styled.div`
  flex: ${p => p.flexGrow === undefined ? 1 : p.flexGrow} 1 ${p => widthMap[p.colWidth] || 100}px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 15px;
  // &:last-child {
  //   text-align: right;
  // }

  display: table;
  height: 100%;
  min-width: 10px;
`

const CellInnerWrapper = styled.span`
  display: table-cell;
  vertical-align: middle;
`

export const CellWrapper = ({ children, ...rest }) => (
  <CellWrapper_ {...rest}>
    <CellInnerWrapper>
      {children}
    </CellInnerWrapper>
  </CellWrapper_>
)

const ColumnRowWrapper = styled.div`
  display: flex;

  height: ${p => p.height}px;
  width: ${p => p.width}px;

  border-bottom: 1px solid ${tc('light4')};

`

const HeaderColumnBase = styled.div`
  flex: ${p => p.flexGrow === undefined ? 1 : p.flexGrow} 1 ${p => widthMap[p.colWidth] || 100}px;
  padding: 0 15px;

  min-width: 10px;

  // &:last-child {
  //   text-align: right;
  // }
`

export const widthMap = {
  MINI: 50,
  SMALL: 30,
  DEFAULT: 100,
  MEDIUM: 200,
  LARGE: 500
}

const ColumnRow = ({ columns, HeaderColumn, headerColumnProps, toggleSearch, height, width, padding }) => {
  const content = columns.map((column, dx) => {
    return (
      <HeaderColumnBase key={dx} colWidth={column.colWidth} flexGrow={column.flexGrow}>
        {HeaderColumn ? <HeaderColumn column={column} toggleSearch={toggleSearch} {...headerColumnProps} /> : column.columnName}
      </HeaderColumnBase>
    )
  })

  return (
    <ColumnRowWrapper height={height} width={width} padding={padding}>
      {content}
    </ColumnRowWrapper>
  )
}

const DefaultRow = ({ style, index, data }) => {
  return (
    <div style={style}>
      {data[index]}
    </div>
  )
}



const WindowedTable = ({ data, columns, Row, HeaderColumn, headerColumnProps, overscanCount, padding, forPrint }) => {
  const [extraHeaderHeight, setExtraHeaderHeight] = React.useState(0)
  const [modes, setModes] = React.useState({})
  const rowHeight = 60
  const rowCount = data.rows.length

  const toggleSearch = React.useCallback((status) => {
    if (modes.SEARCH) {
      let searchCount = modes.SEARCH.count
      if (status === true) searchCount += 1
      else if (status === false) searchCount -= 1

      if (searchCount === 0) {
        setModes({ ...modes, SEARCH: undefined })
        setExtraHeaderHeight(prevHeight => prevHeight - 40)
      } else {
        setModes({ ...modes, SEARCH: { ...modes.SEARCH, count: searchCount } })
      }
    } else {
      setModes({ ...modes, SEARCH: { count: 1 } })
      setExtraHeaderHeight(prevHeight => prevHeight + 40)
    }
  }, [modes])

  const headerHeight = 40 + extraHeaderHeight
  let calculatedHeight = (rowHeight * rowCount)

  const maxHeight = window.innerHeight - 160 - extraHeaderHeight
  let tableHeight = calculatedHeight < maxHeight ? calculatedHeight : maxHeight

  const RowCls = Row || DefaultRow
  const renderRow = props => RowCls({ ...props, wrapperProps: { padding: padding } })

  if (forPrint) {
    tableHeight = calculatedHeight
  }

  // if (forPrint) {
  //   return (
  //     <table style={{width: '100vw'}}>
  //       <thead>
  //         <tr>
  //           {columns.map((column, dx) => (
  //             <td key={dx} style={{width: `${1/columns.length*100}%`, textTransform: 'uppercase'}}>
  //               {column.label}
  //             </td>
  //           ))}
  //         </tr>
  //       </thead>

  //       <tbody>
  //         {data.rows.map((row, dx) => (
  //           <tr key={dx}>
  //             {columns.map((column, colDx) => (
  //               <td key={colDx} style={{paddingBottom: 5}}>
  //                 {column.cellRenderer({cellData: row[column.dataKey], columnData: column.columnData})}
  //               </td>
  //             ))}
  //           </tr>
  //         ))}
  //       </tbody>
  //     </table>
  //   )
  // }

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <>
          <ColumnRow padding={padding} columns={columns} HeaderColumn={HeaderColumn} headerColumnProps={headerColumnProps} toggleSearch={toggleSearch} height={headerHeight} width={width} />
          <List
            itemData={data}
            itemKey={(dx, item) => item.rows[dx].id}
            itemSize={rowHeight}
            itemCount={rowCount}
            height={tableHeight}
            width={width}
            overscanCount={overscanCount}
          >
            {renderRow}
          </List>
        </>
      )}
    </AutoSizer>
  )
}

export default WindowedTable
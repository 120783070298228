import React from 'react'

// Vendor
import DatePicker from 'react-datepicker'

import { useField } from 'react-final-form'
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import tr from 'date-fns/locale/tr';
registerLocale('tr', tr)
setDefaultLocale('tr')
// Volcano
import { Box, Flex } from 'volcano'

const Input = props => <Box as='input' sx={{
  ...props.sx,
  width: '100%',
  px: 2,
  py: 2
}} {...props} />


const RfDateField = ({ name }) => {
  const { input, meta } = useField(name)

  return (
    <Box sx={{
      // color: '#000'
      ".react-datepicker-wrapper": {
        display: 'block'
      }
    }}>
    <DatePicker
      selected={input.value}
      showMonthDropdown
      onChange={date => input.onChange(date)}
      showTimeSelect
      customInput={<Input/>}
      timeFormat="HH:mm"
      timeIntervals={15}
      locale='tr'
      timeCaption="time"
      dateFormat="d.MM.yyyy h:mm"
      withPortal
      // popperPlacement="bottom"
      popperModifiers={{
        // offset: {
        //   enabled: true,
        //   offset: "5px, 10px"
        // },
        // shift: {
        //   enabled: true
        // },
        // keepTogether: {
        //   enabled: false
        // },
        // flip: {
        //   enabled: false
        // },
        // hide: {
        //   enabled: true
        // },
        // preventOverflow: {
        //   // enabled: false,
        //   escapeWithReference: false,
        //   // boundariesElement: "scrollParent"
        // }
      }}
    />
    </Box>
  )
}

export default RfDateField
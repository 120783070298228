import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Runic
import { useNavigate, useParams, Link } from 'runic-aura/router'
import authenticationActions from 'runic/systems/authentication/actions'
import { TextField, CheckboxField } from 'runic-aura/components/RunicField'
import Form, { useRunicForm, yup } from 'runic-aura/components/RunicForm'

// Volcano
import { Flex, Box } from 'volcano'
import Button from 'volcano/components/Button'
import Card from 'volcano/components/Card'


const AuthenticationForgot = props => {
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated)
  const navigate = useNavigate()

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate(`/home`)
    }
  }, [isAuthenticated])

  const [formProps, isSubmitting] = useRunicForm(authenticationActions.forgot, {
    // action: (data, { setSubmitting }) => {
    //   return [
    //     loginData,
    //     // {
    //     //   success: () => navigate.push(`/home`),
    //     // }
    //   ]
    // }
  })

  if (formProps.phase === 'DONE') {
    return (
      <Flex alignItems='center' justifyContent='center'>
      <Box width={[1 / 2, 1 / 2, 1 / 2, 1 / 2, 1 / 4]} mt='200px' pr='30px' pt='25px'>
        <Card>
          <Form {...formProps}>
            <Box sx={{
              fontWeight: 'bold',
              mb: 3
            }}>
              Şifremi Unuttum
            </Box>
            Belirttiğiniz e-posta adresine şifrenizi yenilemeniz için yapmanız gerekenleri içeren bir e-posta gönderdik.
            <Flex sx={{
              flexDirection: 'row-reverse',
              mt: 3
            }}>
              <Link to='../login' tabIndex={5}>
              Girişe geri dön
              </Link>
            </Flex>
          </Form>
        </Card>
      </Box>
    </Flex>
    )
  }

  return (
    <Flex alignItems='center' justifyContent='center'>
      <Box width={[1 / 2, 1 / 2, 1 / 2, 1 / 2, 1 / 4]} mt='200px' pr='30px' pt='25px'>
        <Card>
          <Form {...formProps}>
            <Box sx={{
              fontWeight: 'bold',
              mb: 3
            }}>
              Şifremi Unuttum
            </Box>
            <TextField name="email" label='E-Posta' tabIndex={1} schema={yup.string().min(4).required()} required focus />
            <Button label='GÖNDER' fullWidth tabIndex={4} submit working={isSubmitting} />
            <Flex sx={{
              flexDirection: 'row-reverse',
              mt: 3
            }}>
              <Link to='../login' tabIndex={5}>
              Girişe geri dön
              </Link>
            </Flex>
          </Form>
        </Card>
      </Box>
    </Flex>
  )
}

export default AuthenticationForgot

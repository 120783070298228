import React from 'react'

// Vendor
import { useField } from 'react-final-form'

// Reverb
import BareTextField from 'volcano/components/TextField'
import { Checkbox, Label } from 'volcano/components/Checkbox'
import { Box, Spacer, styled, t, tt, tc } from 'volcano'
import Dropdown from 'volcano/components/Dropdown'

export FileField from './FileField'


const FieldError = styled.div`
  font-size:  ${tt('input.fontSizeError', 'fontSizes.s')};
  color:  ${tt('input.colorError', 'colors.accent')};

  margin-top:  ${t('space.1')}px;
  margin-left: 13px;

`

const Error = ({ error }) => {
  if (!error) return null

  let message
  if (error.type === 'min') message = `En az ${error.message.min} karakter olmalı.`
  else if (error.type === 'email') message = `Geçerli bir eposta adresi girmelisin.`
  else {
    console.log('Unknown error type', error)
    message = "Geçersiz."
  }

  return (
    <FieldError>
      {message}
    </FieldError>
  )
}

export const TextField = React.forwardRef((props, ref) => {
  const { name, ...rest } = props
  const { input, meta } = useField(name)
  // console.log('- test', input.name, input.value)
  return (
    <Spacer mb={3}>
      <BareTextField {...input} {...rest} />
      <Error error={meta.error} />
    </Spacer>
  )
})

export const DropdownField = React.forwardRef((props, ref) => {
  const { name, ...rest } = props
  const { input, meta } = useField(name)
  // console.log('- test', input.name, input.value)
  return (
    <Spacer mb={3}>
      <Dropdown inputProps={input} {...rest}/>
      <Error error={meta.error} />
    </Spacer>
  )
})

export const CheckboxField = React.forwardRef((props, ref) => {
  const { name, label, ...rest } = props
  const { input, meta } = useField(name, {type: 'checkbox'})

  return (
    <Spacer mb={3}>
      <Label>
        <Checkbox
          {...input} {...rest}
        />
        {label}
      </Label>
      <Error error={meta.error} />
    </Spacer>
  )
})

export const RadioField = React.forwardRef((props, ref) => {
  const { name, options, label, ...rest } = props
  return (
    <Spacer my={4}>
      <Box sx={{
        fontWeight: 'bold',
        fontSize: 's',
        mb: 2
      }}>
        {label}
      </Box>
      {options.map((option, dx) => {
        const { input, meta } = useField(name, {type: 'radio', value: option.value})

        return (
          <Box as='label' key={dx} sx={{
            mr: 3
          }}>
          <input
            {...input}
            type='radio'
            ref={ref}
          />
          {' '} {option.label}
        </Box>
        )
      })}
      {/* <Error error={meta.error} /> */}
    </Spacer>
  )
})


export const RadioOptionField = React.forwardRef((props, ref) => {
  const { name, value, label, description } = props
  const { input, meta } = useField(name, { type: 'radio', value: value })
  return (
    <Spacer my={1}>
      <Box as='label' sx={{
        mr: 3
      }}>
        <Flex>
          <Box as='input'
            {...input}
            type='radio'
            ref={ref}
            sx={{
              mt: '2px'
            }}
          />
          <Box sx={{
            ml: 2
          }}>
            <Box sx={{
              fontWeight: description ? 'bold' : 'normal'
            }}>
              {label}
            </Box>
            {description && <Box sx={{
              mt: 2
            }}>
              {description}
            </Box>}
          </Box>
        </Flex>
      </Box>
      {/* <FieldError error={meta.error} /> */}
    </Spacer>
  )
})

export const ShowIf = ({
  name,
  value,
  comparison,
  children
}) => {
  const formState = useFormState()
  const formValue = formState.values?.[name]
  if (comparison === 'EQUAL') {
    if (formValue === value) return children
  }

  if (comparison === 'NOT_EQUAL') {
    if (formValue !== value) return children
  }
  return null
}
import React from 'react'

// Runic
import { useFetchSourceData } from 'runic/hooks/source'
import { useNavigate } from 'runic-aura/router'
import Content, { ContentHeader } from 'volcano/components/Content'
import useAppState from 'runic/hooks/useAppState'

// Volcano
import { Flex, Box } from 'volcano'

const Link = ({
  to,
  children,
  sx
}) => {
  const navigate = useNavigate()
  const onClick = React.useCallback((e) => {
    e.preventDefault()
    navigate(to)
  }, [to])

  return (
    <Box as='a' sx={sx} href={to} onClick={onClick}>
      {children}
    </Box>
  )
}


const RxAccountSelect = ({
  domain
}) => {
  const navigate = useNavigate()
  const [data, refresh] = useFetchSourceData('Kvkkpro', 'Account', 'account_list')

  const {user} = useAppState()

  React.useEffect(() => {
    if (user.state === 'PRESIDENT') return
    if (data && data.length == 1) navigate(`../r/${data[0].id}/${domain.insideIndexPath}`)
  }, [data])

  if (!data) return null
  return (
    <Flex pl={70} pr={40} py={10}>
      <Box width={1}>
        <ContentHeader>
          Hesap Seçimi
        </ContentHeader>
        <Flex sx={{
          flexWrap: 'wrap',
          mx: -2
        }}>
        {data?.map((account, dx) => (
          <Link key={dx} to={`../r/${account.id}/${domain.insideIndexPath}`} sx={{
            flex: '0 0 200px',
            bg: '#FFF',
            borderRadius: 2,
            p: 3,
            mx: 2,
            border: '1px solid #FFF',
            borderColor: 'primary'
          }}>
            {account.name}
          </Link>
        ))}
        </Flex>
        {user.state === 'PRESIDENT' && <TemplateSelector domain={domain}/>}
      </Box>
    </Flex>
  )
}

const TemplateSelector = ({
  domain
}) => {
  const [data, refresh] = useFetchSourceData('Kvkkpro', 'Account', 'all_account_list', {kind: 2})
  return (
    <Box mt={4}>
    <ContentHeader>
      Şablon Seçimi
    </ContentHeader>
    <Flex sx={{
          flexWrap: 'wrap',
          mx: -2
        }}>
        {data?.map?.((account, dx) => (
          <Link key={dx} to={`../r/${account.id}/${domain.insideIndexPath}`} sx={{
            flex: '0 0 200px',
            bg: '#FFF',
            borderRadius: 2,
            p: 3,
            mx: 2,
            border: '1px solid #FFF',
            borderColor: 'primary'
          }}>
            {account.name}
          </Link>
        ))}
        </Flex>
    </Box>
  )
}

export default RxAccountSelect
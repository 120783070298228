import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Volcano
import { Flex, Box } from 'volcano'
import { useDetailView } from 'runic/hooks/model'
import Text from 'volcano/components/Text'

// RncUi
import { SidebarGroupWrapper, SidebarGroupHeader, RncMenuItem, SidebarMenuTextWrapper } from 'runic-aura/apps/core/components/RunicSidebar'
import useRncDomainMenu from 'runic-aura/apps/uitree/hooks/useRncDomainMenu'
import { useFetchSourceData } from 'runic/hooks/source'


export const RncExtendedMenu = props => {
  const { activeDomain, units, activeUnit, uiConfig } = props

  const activeAccountId = useSelector(state => state.runicAura.directory.activeAccountId)

  if (activeAccountId === null || activeAccountId === undefined) {
    return (
      <SidebarMenuTextWrapper>
        Devam etmek için seçiminizi yapın.
      </SidebarMenuTextWrapper>
    )
  }
  const basePath = (activeAccountId !== null && activeAccountId !== undefined) ? `${props.basePath}r/${activeAccountId}/` : props.basePath

  const menuContent = <MenuContent activeAccountId={activeAccountId} activeDomain={activeDomain} units={units} activeUnit={activeUnit} uiConfig={uiConfig} basePath={basePath}/>

  return (
    <>
    <AccountInfo activeAccountId={activeAccountId}/>
    {menuContent}
    </>
  )
}

const MenuContent = ({
  activeDomain,
  activeAccountId,
  units,
  activeUnit,
  uiConfig,
  basePath
}) => {
  const [accountUserInfo, refresh, isFetching] = useFetchSourceData('Kvkkpro', 'Account', 'user_info', { rxAccountId: activeAccountId })
  if (!accountUserInfo) return null
  const accountInfo = accountUserInfo.rx_account

  return activeDomain.groups.map((group, dx) => {
    const groupItems = group.items.filter(menuItem => {
      let accountHasPermission = menuItem?.meta?.account_levels ? accountInfo?.data?.level && menuItem?.meta?.account_levels.includes(accountInfo?.data?.level) : true
      let userHasPermission = menuItem?.meta?.user_levels ? accountUserInfo && menuItem?.meta?.user_levels.includes(accountUserInfo.kind) : true
      return accountHasPermission && userHasPermission
    }).map((menuItem, dx) => {
      return <RncMenuItem unit={units[menuItem.unitName]} menuItem={menuItem} activeUnit={activeUnit} uiConfig={uiConfig} basePath={basePath} key={dx} />
    })
    return (
      <SidebarGroupWrapper key={dx}>
        {activeDomain.groups.length != 1 && groupItems.length > 0 ? <SidebarGroupHeader><Text textCase='upper' id={group.name} /></SidebarGroupHeader> : null}
        {groupItems}
      </SidebarGroupWrapper>
    )
  })
}

const linkKindMap = {
  ADMIN: 'Yönetici',
  MANAGER: 'Birim Amiri',
  EMPLOYEE: 'Personel',
  VIEWER: 'Görüntüleyici'
}

const AccountInfo = ({
  activeAccountId
}) => {
  const dvInstance = useDetailView({ modelName: 'RxAccount', value: activeAccountId })
  const [accountUserInfo, refresh, isFetching] = useFetchSourceData('Kvkkpro', 'Account', 'user_info', { rxAccountId: activeAccountId })
  if (!dvInstance.entity || !dvInstance.view) return null
  if (!accountUserInfo) return null
  console.log(accountUserInfo)

  return (
    <Box sx={{
      color: '#FFF',
      px: 3,
      mb: 2
    }}>
      {dvInstance.entity.data.accountData.legalName || dvInstance.entity.name}
      <Box sx={{
        mt: 3,
        mb: 3
      }}>
       {accountUserInfo.rc_user.name} - {linkKindMap[accountUserInfo.kind]}
      </Box>
    </Box>
  )
}

export const RncQuickMenu = props => {
  const { activeDomain, units, activeUnit, uiConfig, basePath } = props
  return activeDomain.quickMenu.map((menuItem, dx) => <RncMenuItem unit={units[menuItem.unitName]} menuItem={menuItem} activeUnit={activeUnit} uiConfig={uiConfig} basePath={basePath} key={dx} />)
}

const RxAccountSidebarMenu = ({
  uiConfig,
  activeZone,
  activeDomain
}) => {
  const menuProps = useRncDomainMenu(
    uiConfig,
    activeZone,
    activeDomain
  )

  return uiConfig.extended ? <RncExtendedMenu {...menuProps} /> : <RncQuickMenu {...menuProps} />
}

export default RxAccountSidebarMenu
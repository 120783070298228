import React from 'react'

// Vendor
import { Global, css } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'

const Volcano = ({
  config = {},
  theme,
  children,
  globalCss = () => {}
}) => {
  let global
  let fontCss = ``
  if (config.fonts) {
    config.fonts.forEach(font => fontCss += `
      @font-face {
        font-family: ${font.fontFamily};
        src: url('/static/fonts/${font.name}');
        font-weight: ${font.fontWeight};
      }
    `
    )
  }
  const finalStyles = css`
  ${globalCss(theme)}
  ${fontCss}
  `
  global = <Global styles={finalStyles}/>
  return (
    <ThemeProvider theme={theme}>
      {global}
      {children}
    </ThemeProvider>
  )
}

export default Volcano
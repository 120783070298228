  // Recraft - Function
import { createActions } from 'runic/core/redux'

const BASE_COMPONENT = 'RsFile'

const ACTIONS = {
  CUSTOM: {
    RCR_START_UPLOAD: {hasProgress: true},
    RCR_RETRY_UPLOAD: {hasProgress: true},
    RCR_UPLOAD: {},
  },
  LOCAL: {
    RCR_UPLOAD_PROGRESS: {},
    RCR_CLEAR_UPLOAD_DATA: {},
    RCR_REMOVE_FAILED_UPLOAD: {}
  }
}

export default createActions(ACTIONS, 'upload', BASE_COMPONENT)

import React from 'react'

// Vendor
import { useNavigate } from "runic-aura/router";

// Runic
import useAppState from 'runic/hooks/useAppState'

// Volcano
import { getInitials } from 'volcano/util'
import { styled, t, tt, tc, Flex, Box } from 'volcano'

import { Menu, MenuItem } from 'volcano/components/Menu'
import Icon from 'volcano/components/Icon'

// Reverb - Component
// import { Menu, MenuList, MenuItem } from 'reverb/components/menu'


const UserMarkWrapper = styled.a`
  cursor: pointer;
  padding-bottom: 5px;
`;

const TextIcon = styled.div`
  background: ${tc('light4')};
  border-radius: ${t('radii.1')};
  padding-left: 8px;
  padding-right: 8px;

  padding-top: 8px;
  padding-bottom: 8px;

  font-size: ${t('fontSizes.s')};
  text-align: center;

  padding-top: 8px;

  display: flex;
  align-items: center;
  float: left;

  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.1);

  transition: all 0.2s ease;

  &:hover {
    background: ${tc('light')};
  }
`

const UserIcon = React.forwardRef(({name, ...rest}, ref) => (
  <TextIcon ref={ref} {...rest}>
    <Box>
    {getInitials(name)}
    </Box>
    <Icon name='chevron-down' size={12} color='primary' sx={{
      ml: 1
    }}/>
  </TextIcon>)
)

import RfSingleAction from 'runic-form/components/RfSingleAction'

const RncUserMark = props => {
  const [mode, modeSet] = React.useState('MENU')
  const navigate = useNavigate()
  const {user} = useAppState()
  if (!user) return false

  let content
  if (mode === 'MENU') {
    content = (
      <>
        <MenuItem onClick={() => modeSet('PASSWORD')}>Şifre Değiştir</MenuItem>
        <MenuItem onClick={() => navigate('/auth/logout')}>Çıkış</MenuItem>
      </>
    )
  } else if (mode === 'PASSWORD') {
    content = (
      <RfSingleAction formOnly actionKey='RcApp.SetRcUserOwnPassword' fields={[
        {
          label: 'Şifre',
          kind: 'password',
          name: 'password',
          required: true,
        },

        {
          label: 'Şifre (Tekrar)',
          kind: 'password',
          name: 'passwordAgain',
          required: true,
        },
      ]} initialValues={{id: user.id}} buttonText='KAYDET' onCancel={() => modeSet('MENU')} onSuccess={() => modeSet('MENU')}/>
    )
  }
  return (
    <UserMarkWrapper>
      <Menu anchor={<UserIcon name={user.name}/>} placement='bottom-start'>
        {content}
      </Menu>
    </UserMarkWrapper>
  )
}

export default RncUserMark

// Runic
import { createActions } from 'runic/core/redux'

export default createActions({
  CUSTOM: {
    rcrLogin: {},
    rcrLogout: {},
  },
  RCR_ACTION: {
    forgot: {},
    checkResetToken: {},
    checkEmailVerifyToken: {},
    resetPassword: {},
    changePassword: {}
  },
  RCR_SOURCE: {
    syncLoginState: {source: 'RunicState'},
  }
}, 'authentication', 'RcApp')

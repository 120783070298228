import React from 'react'

// Volcano
import { Flex, Box } from 'volcano'

import Entity from 'runic-aura/apps/model/components/Entity'
import EntityActionGroupMenu from 'runic-aura/apps/model/components/EntityActionGroupMenu'

import Icon from 'volcano/components/Icon'
import { ManagedModal } from 'volcano/components/Modal'


const ModelListViewGrid = ({
  lvInstance,
  filterInstance,
  options
}) => {
  if (!lvInstance.status.isReady) return null

  console.log(lvInstance)

  return (
    <Box>
      <Flex sx={{
        mx: -2,
        // justifyContent: 'space-between',
        flexWrap: 'wrap'
      }}>
        {lvInstance?.listView?.list?.items?.map((item, dx) => {
          return (
            <Box key={dx} sx={{
              flex: '1 1 0',
              maxWidth: 200,
              minWidth: 200,
              mb: 3,
              bg: '#FFF',
              mx: 2,
              borderRadius: 1,
              boxShadow: "0 1px 3px 0 rgba(21,27,38,.15)"
            }}>
              <Entity id={item} modelName='RnxBroadcastMedia'>
                {(entity) => {
                  console.log(entity)
                  if (!entity || !entity.rx_file) return null
                  return (
                    <Box>
                      <Entity id={entity.rx_file} modelName='RxFile'>
                        {(rxFileEntity) => {
                          console.log(rxFileEntity)
                          if (!rxFileEntity) return null
                          console.log('***', entity)
                          if (entity.kind == 1) {
                            return (
                              <Box sx={{
                                background: `url(/cdn/assets/${rxFileEntity.rc_tenant_id}/${rxFileEntity.data.file_full_name}) center center`,
                                backgroundSize: 'cover',
                                width: '100%',
                                ':before': {
                                  content: "''",
                                  display: 'block',
                                  pb: '56%'
                                }
                              }} />
                            )
                          } else {
                            return (
                              <Box sx={{
                                width: '100%',
                                position: 'relative',
                                ':before': {
                                  content: "''",
                                  display: 'block',
                                  pb: '56%'
                                }
                              }}>
                                <Box sx={{
                                  position: 'absolute',
                                  top: 'calc(50% - (64px / 2))',
                                  left: 'calc(50% - (64px / 2))'
                                }}>
                                  <ManagedModal anchor={<Icon name='video-play-empty' color='primary' size={64} />}>
                                    <Box sx={{
                                      width: '50vw',
                                      // height: '50vh',
                                      bg: '#FFF',
                                      overflow: 'hidden'
                                    }}>
                                      <Box as='video' controls src={`/cdn/assets/${rxFileEntity.rc_tenant_id}/${rxFileEntity.data.file_full_name}`} sx={{
                                        width: '100%'
                                      }} />
                                    </Box>
                                  </ManagedModal>
                                </Box>
                              </Box>
                            )
                          }

                        }}
                      </Entity>
                      <Flex sx={{
                        my: 2,
                        mx: 2,
                        justifyContent: 'space-between',
                      }}>
                        <Box sx={{
                          textOverflow: 'ellipsis',
                          width: '100%',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden'
                        }}>
                          {entity.name}
                        </Box>
                        <Box>
                          <EntityActionGroupMenu entity={entity} modelName={entity._rcr_model_name} />
                        </Box>
                      </Flex>

                    </Box>
                  )
                }}
              </Entity>
            </Box>
          )
        })}
      </Flex>
    </Box>
  )
}

export default ModelListViewGrid
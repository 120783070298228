import React from 'react'

// Vendor
import * as _yup from 'yup'
export const yup = {}
Object.entries(_yup).forEach(([k, v]) => yup[k] = v)

import { useDispatch } from 'react-redux'
import { Form as RFForm } from 'react-final-form'
export { Field } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import Axios from 'axios'

// Volcano
import Alert from 'volcano/components/Alert'
import { mapKeys } from 'lodash'

export const RunicFormContext = React.createContext()

export const Form = ({
  validate,
  renderSuccess,
  initialValues,
  children,
  phase,
  handleSubmit,
  error,
  ctx
}) => {

  const formElement = (
    <RFForm
    onSubmit={handleSubmit}
    mutators={{
      ...arrayMutators
    }}
    initialValues={initialValues}
    keepDirtyOnReinitialize={true}
    validate={validate}>
      {({handleSubmit, form}) => (
        <form onSubmit={handleSubmit}>
          {renderSuccess && phase === 'DONE' && renderSuccess()}
          {!(renderSuccess && phase === 'DONE') && children}
          {/* <FormError/> */}
        </form>
      )}
    </RFForm>
  )

  if (ctx) {
    return (
      <RunicFormContext.Provider value={{
        entityMap: {},
        ...ctx,
      }}>
        {formElement}
      </RunicFormContext.Provider>
    )
  } else {
    return formElement
  }
}

export default Form

// export const FormError = ({error}) => {
//   if (error) {
//     return error.kind ? (
//       <Alert mb={2} kind='warning'><Translation sentence>{`error.${error.kind}`}</Translation>.</Alert>
//     ) : (
//       <Alert mb={2} kind='warning'>Bir hata oluştu.</Alert>
//     )
//   } else {
//     return null
//   }
//   error && error.kind ? <Alert kind='warning'><Translation sentence>{`error.${error.kind}`}</Translation>.</Alert> : null
// }

export const useUrlForm = ({
  url,
  data,
  onSuccess,
  onError,
  getData,
  ...props
}) => {
  const [phase, setPhase] = React.useState('FORM')
  const [error, setError] = React.useState(null)
  const [result, setResult] = React.useState([])
  const [isSubmitting, setSubmitting] = React.useState(false)

  const handleSubmit = React.useCallback(async (values, form, callback) => {
    setSubmitting(true)
    let formData = {...values, ...data}
    if (getData) formData = {...formData, ...getData()}
    const response = await Axios.post(url, formData).catch(e => {
      setSubmitting(false)
      setError(e)
      onError?.(e)
    })
    if (response) {
      setSubmitting(false)
      setResult(response.data)
      onSuccess?.(response.data)
    }
  }, [])

  return ([{
    ...props,
    phase,
    error,
    handleSubmit
  }, isSubmitting])
}

export const useRunicForm = (
  actionCreator,
  callbacks = {},
  options = {}
) => {
  const [phase, setPhase] = React.useState('FORM')
  const [error, setError] = React.useState(null)
  const [result, setResult] = React.useState([])
  const [isSubmitting, setSubmitting] = React.useState(false)

  const dispatch = useDispatch()

  const handleSubmit = (data, options) => {
    let actionData = data
    let dataCallbacks = {}

    if (callbacks.action) {
      const [newActionData, newCallbacks] = callbacks.action(data, options)
      actionData = newActionData
      dataCallbacks = newCallbacks
    }
    delete actionData._client
    setSubmitting(true)
    setError(null)
    return dispatch(actionCreator(actionData, {
      success: (payload) => {
        setPhase('DONE')
        setError(null)
        setResult(payload)
        setSubmitting(false)
        callbacks?.success?.(payload)
        dataCallbacks?.success?.(payload)
      },
      error: (payload) => {
        setPhase('ERROR')
        setError(payload)
        setResult(payload)
        setSubmitting(false)
        callbacks?.error?.(payload)
        dataCallbacks?.error?.(payload)
      }
    }))
  }

  return ([{
    // ...props,
    ...options,
    phase,
    error,
    handleSubmit,
    result
  }, isSubmitting])
}

export const FormError = ({
  error,
  errorMap
}) => {
  if (!error) return null
  let message = "Bir hata oluştu."
  if (errorMap) {
    const kind = error.kind || error.type
    const mappedKind = errorMap[kind]
    if (mappedKind) message = mappedKind
  }
  return (
    <Alert sx={{
      mb: 2
    }}>
      {message}
    </Alert>
  )
}
import React from 'react'

// Rncui
import { useNavigate } from 'runic-aura/router'


const useRncIndexRedirect = (match, indexUrl, redirectUrl) => {
  const navigate = useNavigate()

  React.useEffect(() => {
    const urlFragmentToCheck = match.url.slice(match.url.length - indexUrl.length - 2, match.url.length)
    if (urlFragmentToCheck == `/${indexUrl}/`) navigate(`${redirectUrl}/`, { replace: true })
  }, [match])
}

export default useRncIndexRedirect
import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'
import { CustomPicker } from 'react-color'
import { isValidHex } from 'react-color/lib/helpers/color'

// Volcano
import { Box, Flex } from 'volcano'
import Icon from 'volcano/components/Icon'
import { Transforms } from 'slate'


const RfColorPicker = ({
  hex,
  rgb,
  onColorChange,
  onChange,
  editor,
}) => {
  const theme = useSelector(state => state.br.theme)
  const inputRef = React.useRef()
  const selectionRef = React.useRef()
  if (!selectionRef.current) selectionRef.current = editor.selection

  const [value, valueSet] = React.useState(hex)
  console.log('--\n', editor.selection, selectionRef.current)

  const onTextChange = e => {
    const newVal = e.target.value
    valueSet(newVal)
    if (!isValidHex(newVal)) {
      return
    }
    if (!editor.selection && selectionRef.current) Transforms.select(editor, selectionRef.current)
    onColorChange(newVal)
    onChange({
      hex: newVal
    })
  }

  const onColorSelect = (e, newVal) => {
    e.preventDefault()
    if (!editor.selection && selectionRef.current) Transforms.select(editor, selectionRef.current)
    onColorChange(newVal)
    onChange({
      hex: newVal
    })
  }

  return (
    <Flex sx={{
      width: '250px',
      flexWrap: 'wrap',
      mx: -1,
      bg: '#FFF',
      p: 2,
      boxShadow: "0 1px 3px 0 rgba(21,27,38,.15)",
      border: '1px solid #FFF',
      borderColor: 'primary'
    }}>
      {Object.values(theme.palette).map((clr, dx) => {
        return (
          <Box as='a' key={dx} sx={{
            bg: clr,
            width: 30,
            height: 30,
            mx: 1,
            mb: 1,
            borderRadius: 2,
            cursor: 'pointer',
            display: 'block',
            border: '1px solid #000',
          }} onMouseDown={e => onColorSelect(e, clr)}>

          </Box>
        )
      })}
      <Flex as='a' sx={{
            bg: '#FFF',
            width: 30,
            height: 30,
            mx: 1,
            mb: 1,
            borderRadius: 2,
            cursor: 'pointer',
            display: 'block',
            // border: '1px solid #000',
            alignItems: 'center',
            textAlign: 'center'
          }} onMouseDown={e => onColorSelect(e, null)}>
            <Icon name='square-remove' sx={{
              mx: 'auto'
            }} size={24} color='primary'/>
          </Flex>
      <Box as='input' sx={{
        width: '100%',
        p: 2,
        mx: 1,
        mt: 1
      }} ref={inputRef} onBlur={e => {
        // console.log('sel', selection)
        // Transforms.select(editor, selection)
      }} value={value} onMouseDown={e => {
        // Transforms.select(editor, selection)
        // !selection && selectionSet({...editor.selection})
      }} onChange={onTextChange}/>
    </Flex>
  )
}

export default CustomPicker(RfColorPicker)
import React from 'react'

import usePopper from 'volcano/hooks/usePopper'
import useOnClickOutside from 'volcano/hooks/useOnClickOutside'
import useEventListener from 'volcano/hooks/useEventListener'
import keycode from 'keycode'

const useMenu = ({ placement = 'bottom-start', children, openOnHover, onClose, strategy, modifiers = [], ...rest }) => {
  const referenceNode = React.useRef(null)
  const popperNode = React.useRef(null)
  const arrowNode = React.useRef(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const [isForceOpen, setIsForceOpen] = React.useState(false)
  const [data, dataSet] = React.useState()
  const [, updateState] = React.useState()
  const setReferenceNode = (node, data) => {
    referenceNode.current = node
    setIsOpen(true)
    dataSet(data)
    updateState({})
  }
  // const { style, update, arrowStyle } = usePopper({
  //   referenceNode, popperNode, arrowNode, isOpen, placement, modifiers: {
  //     flip: {
  //       behavior: ['left', 'right', 'top', 'bottom']
  //     },
  //     // preventOverflow: { enabled: false },
  //     // positionFixed: true,
  //     preventOverflow: {
  //       boundariesElement: 'window'
  //       // boundariesElement: 'viewPort'
  //       // boundariesElement: 'scrollParent'
  //     },
  //     // computeStyle: {
  //     //   gpuAcceleration: false
  //     // }
  //   }, ...rest
  // })
  const { style, update, arrowStyle } = usePopper({
    referenceNode, popperNode, arrowNode, isOpen, placement, strategy, modifiers: [
      {
        name: 'flip',
        enabled: true,
        options: {
          // fallbackPlacements: [ 'left', 'right', 'top', 'bottom'],
          // rootBoundary: 'document',
        }
      },

      {
        name: 'preventOverflow',
        options: {
          mainAxis: true, // true by default
          altAxis: true,
        },
      },

      // {
      //   name: 'offset',
      //   options: {
      //     offset: [-16, -5],
      //   },
      // },
      ...modifiers
    ]
  })

  const toggleIsOpen = React.useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpen((curr) => !curr)
    update && update()
    setTimeout(() => update && update(), 100)
  }, [update])

  // React.useEffect(() => {
  //   update()
  //   console.log('update')
  // })

  React.useEffect(() => {
    if (!popperNode.current) return
    const observer = new MutationObserver(mutations => {
      update()
    });

    // configuration of the observer:
    // const config = { attributes: true }
    const config = { childList: true, subtree: true }

    // pass in the target node, as well as the observer options
    observer.observe(popperNode.current, config)

    // later, you can stop observing
    return () => observer.disconnect()

    // setInterval(() => {
    //   update()
    //   console.log('k')
    // }, 1000)
  }, [!!popperNode.current, update])

  const close = React.useCallback(() => {
    setIsOpen(false)
    onClose?.()
  }, [])
  const open = React.useCallback(() => {
    setIsOpen(true)
    // onClose?.()
  }, [])

  const softClose = React.useCallback(() => {
    !isForceOpen && close()
  }, [isForceOpen])
  const handleKeyPress = React.useCallback((e) => e.keyCode === keycode('Esc') && close(), [])

  useOnClickOutside(popperNode, softClose, true)
  useEventListener('keydown', handleKeyPress)

  const anchorProps = {
    // onClick: toggleIsOpen,
    ref: referenceNode
  }

  if (openOnHover) {
    anchorProps.onMouseEnter = () => setIsOpen(true)
    anchorProps.onMouseLeave = () => setIsOpen(false)
  }
  const menuProps = {
    isOpen,
    ref: popperNode,
    style: {
      ...style,
      zIndex: 9999
    },
  }

  const [arrowPlacement] = placement.split("-");
  const transformMap = {
    top: "rotateZ(180deg)",
    right: "rotateZ(-90deg)",
    bottom: "rotateZ(360deg)",
    left: "rotateZ(90deg)"

    // right: "rotateZ(180deg)",
    // top: "rotateZ(-90deg)",
    // left: "rotateZ(360deg)",
    // bottom: "rotateZ(90deg)"
  };

  const arrowProps = {
    ref: arrowNode,
    style: {
      ...arrowStyle,
      position: 'absolute',
      display: isOpen ? 'block': 'none',
      transform: transformMap[arrowPlacement],
      [arrowPlacement]: '100%',
      width: '1em',
      height: '1em',
    },
  }

  return [anchorProps, menuProps, arrowProps, close, open, isForceOpen, setIsForceOpen, setReferenceNode, data]
}

export default useMenu
import React from 'react'

// Vendor
import * as changeCase from 'volcano/util/text'
import { useSelector } from 'react-redux'


const Entity = ({
  modelName,
  id,
  children
}) => {
  const entity = useSelector(state => state.entity[changeCase.camel(modelName)].entity[id])
  if (children) return (children(entity))
  return entity.name
}

export default Entity
import React from 'react'

// Vendor
import { useHref } from 'runic-aura/router'
import { useSelector } from 'react-redux'


const useRncDomainMenu = (uiConfig, activeZone, activeDomain) => {
  const activeUnitName = useSelector(state => state.runicAura.activeUnitName)
  const activeUnit = activeDomain && activeDomain.items.find(item => item.unitName == activeUnitName)
  const activeZoneParams = useSelector(state => state.runicAura.activeZoneParams)
  const units = useSelector(state => state.runicAura.units)

  let zonePath = activeZone.path
  if (activeZone.path.includes(':') && activeZoneParams) {
    Object.keys(activeZoneParams).forEach(paramKey => {
      zonePath = zonePath.replace(`:${paramKey}`, activeZoneParams[paramKey])
    })
  }

  const basePath = useHref(`${activeDomain.path}/`)

  return {
    activeDomain,
    units,
    activeUnit,
    uiConfig,
    basePath
  }
}
export default useRncDomainMenu
// Vendor
import * as changeCase from 'volcano/util/text'

// Riva - Function
import { createReducer, setDraft } from 'runic/core/redux'

import uploadActions from './actions'

const defaultState = {
  models: {}
}

const defaultModelState = {
  uploadingIds: [],
  uploadedIds: [],
  errorIds: [],
  entity: {}
}

export default createReducer(defaultState, {
  // [uploadActions.RCR_UPLOAD_PROGRESS]: (draft, { id, progress, modelName }) => draft.models[modelName].entity[id].progress = progress,

  // [uploadActions.RCR_CLEAR_UPLOAD_DATA]: (draft, { id, modelName }, state) => {
  //   if (!state.models || state.models[modelName]) return
  //   draft.models[modelName].errorIds = []
  //   draft.models[modelName].uploadedIds = []
  // },

  // [uploadActions.RCR_REMOVE_FAILED_UPLOAD]: (draft, { id, modelName }, state) => {
  //   const errorIndex = state.models[modelName].errorIds.indexOf(id)
  //   if (errorIndex == -1) return
  //   delete draft.models[modelName].entity[id]
  //   draft.models[modelName].errorIds.splice(errorIndex, 1)
  // },

  // [uploadActions.RCR_RETRY_UPLOAD]: (draft, { file, modelName }, state) => {
  //   const errorIndex = state.models[modelName].errorIds.indexOf(file.id)
  //   if (errorIndex == -1) return
  //   draft.models[modelName].errorIds.splice(errorIndex, 1)
  // },

  // [uploadActions.RCR_UPLOAD]: {
  //   success: (draft, { fileId, modelName }, state) => {
  //     const uploadingIndex = state.models[modelName].uploadingIds.indexOf(fileId)
  //     if (uploadingIndex == -1) return

  //     draft.models[modelName].uploadingIds.splice(uploadingIndex, 1)
  //     draft.models[modelName].uploadedIds.push(fileId)
  //   },

  //   error: (draft, { file, modelName }, state) => {
  //     const uploadingIndex = state.models[modelName].uploadingIds.indexOf(file.id)
  //     if (uploadingIndex == -1) return

  //     draft.models[modelName].uploadingIds.splice(uploadingIndex, 1)
  //     draft.models[modelName].errorIds.push(file.id)
  //   },

  //   start: (draft, { file, progress, modelName, uploadData}, state) => {
  //     if (!state.models[modelName]) draft.models[modelName] = {...defaultModelState}
  //     draft.models[modelName].uploadingIds.push(file.id)
  //     draft.models[modelName].entity[file.id] = {file, progress, uploadData}
  //   },
  // },
})


// export default createReducer(defaultState, {
//   [selfActions.RCR_UPLOAD_PROGRESS]: (state, action) => {
//     const { id, progress, modelName } = action.payload
//     const stateUpdate = {
//       models: {
//         [modelName]: {
//           entity: {
//             [id]: {
//               progress: {
//                 $set: progress
//               }
//             }
//           }
//         }
//       }
//     }

//     return update(state, stateUpdate)
//   },

//   [selfActions.RCR_CLEAR_UPLOAD_DATA]: (state, action) => {
//     const { id, progress, modelName } = action.payload
//     if (!state.models || !state.models[modelName]) return state

//     const stateUpdate = {
//       models: {
//         [modelName]: {
//           errorIds: {
//             $set: []
//           },
//           uploadedIds: {
//             $set: []
//           }
//         }
//       }
//     }

//     return update(state, stateUpdate)
//   },

//   [selfActions.RCR_REMOVE_FAILED_UPLOAD]: (state, action) => {
//     const { id, modelName } = action.payload
//     const errorIndex = state.models[modelName].errorIds.indexOf(id)
//     if (errorIndex == -1) return state

//     const stateUpdate = {
//       models: {
//         [modelName]: {
//           entity: {
//             [id]: {
//               $set: undefined
//             }
//           },

//           errorIds: {
//             $splice: [[errorIndex, 1]]
//           }
//         }
//       }
//     }

//     return update(state, stateUpdate)
//   },

//   [selfActions.RCR_RETRY_UPLOAD]: (state, action) => {
//     const { file, modelName } = action.payload
//     const fileId = file.id
//     const errorIndex = state.models[modelName].errorIds.indexOf(fileId)

//     const stateUpdate = {
//       models: {
//         [modelName]: {
//           errorIds: {}
//         }
//       }
//     }

//     if (errorIndex != -1) {
//       stateUpdate.models[modelName].errorIds = {
//         $splice: [[errorIndex, 1]]
//       }
//     }

//     return update(state, stateUpdate)
//   },

//   [selfActions.RCR_UPLOAD]: {
//     success: (state, action) => {
//       const { fileId, modelName } = action.payload
//       const uploadingIndex = state.models[modelName].uploadingIds.indexOf(fileId)

//       const stateUpdate = {
//         models: {
//           [modelName]: {
//             uploadingIds: {},
//             uploadedIds: {}
//           }
//         }
//       }

//       if (uploadingIndex != -1) {
//         stateUpdate.models[modelName].uploadingIds = {
//           $splice: [[uploadingIndex, 1]]
//         }

//         stateUpdate.models[modelName].uploadedIds = {
//           $push: [fileId]
//         }
//       }

//       return update(state, stateUpdate)
//     },

//     error: (state, action) => {
//       const { file, modelName } = action.payload
//       const uploadingIndex = state.models[modelName].uploadingIds.indexOf(file.id)

//       const stateUpdate = {
//         models: {
//           [modelName]: {
//             uploadingIds: {},
//             uploadedIds: {}
//           }
//         }
//       }

//       if (uploadingIndex != -1) {
//         stateUpdate.models[modelName].uploadingIds = {
//           $splice: [[uploadingIndex, 1]]
//         }

//         stateUpdate.models[modelName].errorIds = {
//           $push: [file.id]
//         }
//       }

//       return update(state, stateUpdate)
//     },

//     start: (state, action) => {
//       const { file, progress, modelName, uploadData } = action.payload

//       let initialState = state
//       if (!state.models[modelName]) {
//         const initialStateUpdate = {
//           models: {
//             [modelName]: {
//               $set: defaultModelState
//             }
//           }
//         }
//         initialState = update(initialState, initialStateUpdate)
//       }

//       const stateUpdate = {
//         models: {
//           [modelName]: {
//             uploadingIds: {
//               $push: [file.id]
//             },
//             entity: {
//               [file.id]: {
//                 $set: {
//                   file,
//                   progress,
//                   uploadData
//                 }
//               }
//             }
//           }
//         }
//       }

//       return update(initialState, stateUpdate)
//     }
//   },
// })

import React from 'react'

// Vendor
import { useField } from 'react-final-form'

// Volcano
import { Flex, Box } from 'volcano'
import Icon from 'volcano/components/Icon'
import { ButtonIcon } from 'volcano/components/Button'

// RunicAura
import useFileUpload from 'runic-aura/hooks/useFileUpload'


const FileField = ({
  name,
  elementName = 'RxFile',
  actionName = 'UploadFile',
  rxFileKindCodename,
  rxObjectStoreSystemCodename,
}) => {
  const { input, meta } = useField(name)

  const [openFileDialog, state, retryHandler, uploadResult] = useFileUpload({
    acceptedFileKinds: '.csv',
    elementName: elementName,
    actionName: actionName,
    data: {
      rxFileKindCodename,
      rxObjectStoreSystemCodename
    },
    callbacks: {
      success: (response) => {
        input.onChange(response.rcEntity.result.rx_file)
      }
    }
  })

  return (
    <Box>
      <Box as='a' sx={{
        p: 3,
        textAlign: 'center',
        border: '1px dashed',
        borderColor: 'primary',
        width: '100%',
        display: 'block',
        borderRadius: 2,
        my: 3
      }} onClick={openFileDialog}>
        Dosya yükle
      </Box>

      {state.uploadingFiles.length > 0 && (
        <Box>
          <Box sx={{fontWeight: 'bold'}}>Yükleniyor</Box>
          {state.uploadingFiles.map((file, dx) => (
            <Flex key={file.id} p={1} my={2} sx={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <Box>
                <Icon name='square-upload' color='primary' mr={2}/>{file.name}
              </Box>
              {/* <Box>
                <ButtonIcon iconName={'reload'} variant='flat' onClick={() => retryHandler(file)} />
              </Box> */}
            </Flex>
          ))}
        </Box>
      )}

      {state.errorFiles.length > 0 && (
        <Box>
          <Box sx={{fontWeight: 'bold'}}>Hata</Box>
          {state.errorFiles.map((file, dx) => (
            <Flex key={file.id} p={1} my={2} sx={{
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <Box>
                <Icon name='triangle-empty-alert' color='danger' mr={2}/>{file.name}
              </Box>
              <Box>
                <ButtonIcon iconName={'reload'} variant='flat' onClick={() => retryHandler(file)} />
              </Box>
            </Flex>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default FileField
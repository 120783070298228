import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Volcano
import { Flex, Box } from 'volcano'

// RunicAura
import { Routes, Route, useParams } from 'runic-aura/router'
import { useRncActivate } from 'runic-aura/apps/uitree/hooks/tree'

import { useDetailView } from 'runic/hooks/model'
import ModelDetailCard from 'runic-aura/apps/model/components/ModelDetailCard'
import ModelListView from 'runic-aura/apps/model/components/ModelListView'

import { useFetchSourceData } from 'runic/hooks/source'
import useRunicActionCreator from 'runic/hooks/useRunicActionCreator'
import Form, { useRunicForm, yup } from 'runic-aura/components/RunicForm'
import Button from 'volcano/components/Button'
import { TextField, CheckboxField, DropdownField } from 'runic-aura/components/RunicField'
import Text from 'volcano/components/Text'
import Alert from 'volcano/components/Alert'
import Card from 'volcano/components/Card'
import Icon from 'volcano/components/Icon'

import RunicTabs from 'runic-aura/components/RunicTabs'


const TenantDetail = (props) => {
  useRncActivate('detail', { identifier: 'RcApp.RcTenant', hasSubLayout: true }, true)
  const params = useParams()
  const pageProps = null
  const modelName = 'RcApp.RcTenant'.split('.')[1]

  return (
    <DetailComponent modelId={params.unitId} />
  )
}

const DetailComponent = ({
  modelId
}) => {
  const dvInstance = useDetailView({ modelName: 'RcTenant', value: modelId })
  const unit = useSelector(state => state.runicAura.units[state.runicAura.activeUnitName])
  if (!dvInstance.entity || !dvInstance.view) return null
  console.log(unit)
  const tabs = unit.profiles.Default.indexPage?.props?.tabs

  const content = tabs ? <RunicTabs tabs={tabs} ctx={{modelId, dvInstance}}/> : (
    <ModelListView modelName={'RcUser'} segment={{
      name: 'RcTenant.<RcUser',
      params: {
        RcTenant: {
          id: modelId
        }
      },
      system: true
    }} actionContext={{
      rcTenantId: modelId
    }} />
  )

  return (
    <Box>
      <ModelDetailCard entity={dvInstance.entity} modelData={dvInstance.modelData} view={dvInstance.view} noWrapper={true} fieldKind='primary' />
      <Box mt={3} />
      {content}
    </Box>
  )
}

export default TenantDetail

import RncModelUnit from './pages/RncModelUnit'

import ModelListView from './components/ModelListView'
import ModelDetailView from './components/ModelDetailView'

import loadable from '@loadable/component'

const RncModelHome = loadable(() => import('./pages/RncModelHome'))
const RncModelDetail = loadable(() => import('./pages/RncModelDetail'))

export default {
  name: 'rncui.model',
  directory: {
    runicAura: {
      unitHandlers: {
        r1: RncModelUnit
      }
    }
  },
  components: {
    'RncModelHome': {
      component: RncModelHome
    },

    'RncModelDetail': {
      component: RncModelDetail
    },

    'ModelListView': {
      component: ModelListView
    },

    'ModelDetailView': {
      component: ModelDetailView
    }
  },
}
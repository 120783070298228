import React from 'react'


// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Runic
import coreActions from 'runic/systems/core/actions'
import runicAuraActions from 'runic-aura/systems/runic-aura/actions'

const useRunic = ({
  uiConfig,
  runicConfig
}) => {
  const dispatch = useDispatch()
  const appStatus = useSelector(state => state.core.appStatus)

  React.useEffect(() => {
    dispatch(runicAuraActions.setupUi({ config: uiConfig }))
    dispatch(coreActions.runicBootstrap({ config: runicConfig }))
    dispatch(coreActions.bootstrap({}))
  }, [])

  const isReady = appStatus == 'READY'

  return {isReady}
}

export default useRunic
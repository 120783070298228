import React from 'react'


const useRunicAction = (action, modelData) => {
  console.log(action)
  const runicAction = React.useCallback((payload, callbacks) => ({
    type: action.name,
    meta: {
      status: 'START',
      kind: 'RCR_ACTION',
      component: action.elementName || modelData.element,
      actionKind: null
    },
    payload,
    callbacks,
  }), [action, modelData])

  runicAction.actionType = action.name

  return runicAction
}

export default useRunicAction
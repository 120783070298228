import React from 'react'

// Volcano
import { Box, Flex } from 'volcano'

// Runic
import DynamicComponent from 'runic-aura/components/DynamicComponent'
import { useSearchParams } from 'runic-aura/router'


const RunicPrint = props => {
  const [searchParams, setSearchParams] = useSearchParams()

  const componentName = searchParams.get('componentName')
  const componentParams = {}
  for (const key of searchParams.keys()) {
    const val = searchParams.get(key)
    componentParams[key] = val
  }

  return (
    <Box sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      minHeight: '100vh',
      bg: '#FFF',
      zIndex: 99999,
    }}>
      <Box sx={{
        width: '80vw',
        mx: 'auto'
      }}>
        <DynamicComponent componentName={componentName} {...componentParams}/>
      </Box>
    </Box>
  )
}

export default RunicPrint
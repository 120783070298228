import loadable from '@loadable/component'

const DocumentManager = loadable(() => import('./pages/DocumentManager'))
const AccountDocumentManager = loadable(() => import('./pages/AccountDocumentManager'))

export default {
  name: 'document',
  components: {
    'DocumentManager': {
      component: DocumentManager
    },
    'AccountDocumentManager': {
      component: AccountDocumentManager
    },
  },
}
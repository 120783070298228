// Vendor
import * as changeCase from "change-case"
import { takeEvery, call, put, select } from 'redux-saga/effects'
import { runicSourceRequest, runicActionRequest, callCallbacks } from 'runic/core/sagaHelpers'
import { normalize } from 'normalizr'



// Riva
// import coreActions from './actions'
// import modelActions from 'riva/systems/model/actions'
// import createEntitySchemas from 'riva/core/schema'
// import createEntitySelectors from 'riva/core/selector'


function* doRcrAction(action) {
  let actionPayload = action.payload
  let actionPath
  let meta
  if (action.type == 'RCR_CREATE_ENTITY') {
    const { rcrModelName, rcrComponentName, rcrCreateSuffix, rcrActionName, ...restActionPayload } = actionPayload
    const actionName = rcrActionName ? rcrActionName : `Create${rcrModelName}${rcrCreateSuffix || ''}`
    actionPath = `${rcrComponentName}.${actionName}`
    actionPayload = restActionPayload
    meta = {
      rcrModelName,
      rcrComponentName
    }
  } else {
    const actionComponent = action.meta.component
    let actionCamelName = action.meta.search
    if (!actionCamelName) {
      if (action.type.includes('/')) {
        const actionTypeSplit = action.type.split('/')
        const actionName = actionTypeSplit[actionTypeSplit.length-1]
        actionCamelName = changeCase.pascalCase(actionName)
      } else {
        actionCamelName = changeCase.pascalCase(action.type)
      }
  }
    actionPath = `${actionComponent}.${actionCamelName}`
  }

  try {
    const apiResponse = yield call(runicActionRequest, actionPath, actionPayload, action.meta.actionKind || 'action')
    let payload = apiResponse.data, status = 'SUCCESS'

    const callbackResponse = callCallbacks(action, 'afterRequest', payload)
    if (callbackResponse === false) {
      return
    }

    if (apiResponse.data && apiResponse.data._rc_entity) {
      const entitySchemasBySnakeCaseName = yield select((state) => state.model.entitySchemasBySnakeCaseName)

      const entityData = normalize(apiResponse.data._rc_entity, entitySchemasBySnakeCaseName)
      delete payload._rc_entity
      payload['rcEntity'] = entityData
    }

    if (apiResponse.data && apiResponse.data._rc_entity_deleted) {
      payload['deletedRcEntity'] = apiResponse.data._rc_entity_deleted
    }

    if (apiResponse.data && apiResponse.data._rcr_sub_actions) {
      payload = {
        rcrSubActions: apiResponse.data._rcr_sub_actions
      }
      status = 'ERROR'
    }

    yield put({
      type: action.type,
      payload: payload,
      meta: {
        ...action.meta,
        ...meta,
        status: status
      }
    })

    callCallbacks(action, status, payload)
  } catch (error) {
    if (error.response) {
      callCallbacks(action, 'error', error.response.data)

      yield put({
        type: action.type,
        payload: {
          ...actionPayload,
          _error: error.response.data
        },
        meta: {
          ...action.meta,
          status: 'ERROR'
        }
      })
    } else {
      // FIXME: log error and continue in PROD
      throw error
    }
  }
}

function* callRcrSource(action) {
  const archiveComponent = action.meta.component
  const archiveCamelName = changeCase.pascalCase(action.meta.archive)
  let archiveSearchCamelName = action.meta.search
  if (!archiveSearchCamelName) {
    if (action.type.includes('/')) {
      const actionTypeSplit = action.type.split('/')
      const actionName = actionTypeSplit[actionTypeSplit.length-1]
      archiveSearchCamelName = changeCase.pascalCase(actionName)
    } else {
      archiveSearchCamelName = changeCase.pascalCase(action.type)
    }
  }

  // console.log('**', action.type, archiveSearchCamelName, action.type.includes('/'))

  const archivePath = `${archiveComponent}.${archiveCamelName}.${archiveSearchCamelName}`

  try {
    const apiResponse = yield call(runicSourceRequest, archivePath, action.payload, action.meta.sourceUrlPath)
    const versionUpdate = apiResponse && apiResponse.headers && apiResponse.headers['x-recraft-version-update-minor']

    let payload = apiResponse.data
    if (apiResponse.data && apiResponse.data._rc_entity) {
      const entitySchemasBySnakeCaseName = yield select((state) => state.model.entitySchemasBySnakeCaseName)
      // let entitySchemasBySnakeCaseName = yield select((state) => state.model.entitySchemasBySnakeCaseName)
      // if (!entitySchemasBySnakeCaseName) {
      //   console.log('entitySchemasBySnakeCaseName hack')
      //   const models = yield select((state) => state.model.models)
      //   entitySchemasBySnakeCaseName = createEntitySchemas(models).entitySchemasBySnakeCaseName
      // }
      // console.log(entitySchemasBySnakeCaseName)
      const entityData = normalize(apiResponse.data._rc_entity, entitySchemasBySnakeCaseName)
      delete payload._rc_entity
      payload['rcEntity'] = entityData
    }

    if (apiResponse.data && apiResponse.data._rc_entity_filters) {
      payload['rcEntityFilters'] = payload._rc_entity_filters
      delete payload._rc_entity_filters
    }

    yield put({
      type: action.type,
      payload: payload,
      meta: {
        ...action.meta,
        status: 'SUCCESS'
      }
    })

    callCallbacks(action, 'success', payload)

    if (versionUpdate) {
      yield put({
        type: 'RCR_APP_VERSION_UPDATE',
        payload: {kind: 'MINOR'},
        meta: {
          kind: 'LOCAL'
        }
      })
    }
  } catch (error) {
    if (error.response) {
      const meta = {
        ...action.meta,
        status: 'ERROR',
      }

      const callbackPayload = {...error.response}

      if (error.response.data && error.response.data.kind && error.response.data.kind == 'NOT_AUTHORIZED') {
        const _payload = error.response.data.payload || {}
        meta.permissionError = _payload
        callbackPayload.permissionError = _payload
      }

      callCallbacks(action, 'error', callbackPayload)

      yield put({
        type: action.type,
        payload: error.response.data,
        meta
      })
    } else {
      // FIXME: log error and continue in PROD
      throw error
    }

  }
}

function* doRcrLocalAction(action) {
  callCallbacks(action, 'success')
}

// function* doSetup(action) {
//   const sourcePath = `RcSystem.RcrSystem.uiBootstrap`
//   try {
//     const apiResponse = yield call(runicSourceRequest, sourcePath, {rcTenantId: action && action.payload && action.payload.rcTenantId})

//     const modelData = apiResponse.data && apiResponse.data.models
//     const { entitySchemasBySnakeCaseName, entitySchemasByName } = createEntitySchemas(modelData)
//     const entitySelectors = createEntitySelectors(entitySchemasByName, modelData)
//     const modelPayload = {
//       modelData,
//       entitySchemasBySnakeCaseName,
//       entitySelectors,
//     }

//     yield put({
//       type: modelActions.RCR_REGISTER_RECRAFT_MODELS,
//       payload: modelPayload,
//       meta: {
//         kind: 'LOCAL'
//       }
//     })


//     yield put(coreActions.rcrRivaSetup({rcTenantId: 1}))
//     // const appData = apiResponse.data && apiResponse.data.app
//     // const entityData = normalize(apiResponse.data._rc_entity, entitySchemasBySnakeCaseName)
//     // const corePayload = {
//     //   appData,
//     //   rcEntity: entityData
//     // }

//     // yield put({
//     //   type: coreActions.RCR_RIVA_SETUP,
//     //   payload: corePayload,
//     //   meta: {
//     //     kind: 'LOCAL'
//     //   }
//     // })

//     // callCallbacks(action, 'success', payload)
//   } catch (error) {
//     if (error.response) {
//       // const meta = {
//       //   kind: 'LOCAL'
//       //   status: 'ERROR',
//       // }

//       // const callbackPayload = {...error.response}
//       // callCallbacks(action, 'error', callbackPayload)

//       // yield put({
//       //   type: coreActions.RCR_REGISTER_RECRAFT_MODELS,
//       //   payload: error.response.data,
//       //   meta
//       // })
//       // FIXME: add error handling
//     } else {
//       // FIXME: log error and continue in PROD
//       throw error
//     }

//   }
// }

export function* watchRcrSource() {
  yield takeEvery((action) => action.meta && action.meta.status == 'START' && action.meta.kind == 'RCR_SOURCE', callRcrSource)
}

export function* watchRcrAction() {
  yield takeEvery((action) => action.meta && action.meta.status == 'START' && action.meta.kind == 'RCR_ACTION', doRcrAction)
}

export function* watchRcrLocalAction() {
  yield takeEvery((action) => action.meta && action.meta.kind == 'LOCAL', doRcrLocalAction)
}

// export function* watchRcrSetupAction() {
//   yield takeEvery((action) => action.type == 'RCR_UI_BOOTSTRAP', doSetup)
// }

export default [watchRcrSource, watchRcrAction, watchRcrLocalAction]
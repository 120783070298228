import React from 'react'

import ModelListView from 'runic-aura/apps/model/components/ModelListView'


const TenantHome = props => {
  return (
    <ModelListView modelName={'RcTenant'} profileName={null} withActions/>
  )
}

export default TenantHome
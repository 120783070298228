import React from 'react'

// Vendor
import { useField } from 'react-final-form'
import Select from 'react-select'
import { useTheme } from 'emotion-theming'
import { setIn, getIn } from 'final-form'

// Volcano
import { Box, Flex } from 'volcano'
import { Checkbox, Label } from 'volcano/components/Checkbox'


import { useFormState, useForm } from 'react-final-form'

import { RunicFormContext } from './RfForm'

const RfMultiCheckbox = ({
  name,
  field,
  options,
}) => {
  const theme = useTheme()

  const ctx = React.useContext(RunicFormContext)

  const optionGroups = []

  const formState = useFormState({
    subscription: {
      values: true
    }
  })
  console.log(formState.values)

  const unMountRef = React.useRef()

  React.useEffect(() => {
    return () => unMountRef.current = true
  }, [])

  if (field.options_source) {
    if (field.options_source.source == 'FORM') {
      if (field.options_source.dynamicKey) {
        const { source, groupKey, dataKey } = field.options_source.dynamicKey
        const sourceData = getIn(formState.values, source)
        const sourceField = ctx.mainSchema.fields.find(x => x.name === source)

        sourceData?.forEach?.(d => {
          let groupData = d[groupKey]
          let valueData = d[dataKey]

          if (!groupData || !valueData) return

          const groupField = sourceField.fields.find(x => x.name === groupKey)
          const valueField = sourceField.fields.find(x => x.name === dataKey)

          // console.log(valueData, valueField, ctx.entityMap)

          if (groupField.kind === 'relationship') groupData = ctx.entityMap[groupField.target_name][groupData]
          if (valueField.kind === 'relationship') valueData = valueData.map(x => ctx.entityMap[valueField.target_name][x])

          // console.log('**', ctx.entityMap, groupField.target_name, groupData)
          // return

          // console.log('***', groupData)

          const cat = {
            label: groupData?.name || groupData,
            name: groupField.target_name || groupData,
            id: groupData?.id || groupData,
            options: valueData.map(x => ({
              value: x.id,
              label: x.name,
              item: x
            }))
          }

          optionGroups.push(cat)

        })
      }
    }

  }

  return (
    <Box>
      {optionGroups.map((group, gdx) => (
        <Box key={gdx} sx={{
          mb: 3
        }}>
          <Box sx={{
            fontWeight: 'bold',
            mb: 2
          }}>
            {group.label}
          </Box>

          <Box>
            {group.options.map((option, odx) => {
              return (
                <MultiCheckboxfield unMountRef={unMountRef} key={`${name}.${group.id}.${option.value}`} name={`${name}.${group.label}`} value={option.value} field={{label: option.label}}/>
              )
            })}
          </Box>
        </Box>
      ))}
    </Box>
  )
}

const MultiCheckboxfield = ({
  name,
  field,
  value,
  unMountRef
}) => {
  const { input, meta } = useField(name, {
    validate: (value) => {
      let error
      if (field?.required && (
        value === undefined ||
        value === null ||
        value == ''
      )) {
        error = 'Gerekli'
      }
      return error
    }
  })
  const valueRef = React.useRef()
  valueRef.current = input.value

  React.useEffect(() => {
    return () => {
      if (unMountRef.current) return
      if (valueRef.current && valueRef.current.length) {
        const newVal = [...valueRef.current]
        newVal.splice(valueRef.current.findIndex(x => x === value), 1)
        input.onChange(newVal)
      }
    }
  }, [])

  return (
    <Box sx={{
      width: '100%'
    }}>
    <Label>
        <Checkbox
          {...input}
          checked={input.value?.includes(value) ? 'checked' : null}
          onChange={e => {
            if (e.target.checked) input.onChange([...input.value, value])
            else {
              const newVal = [...input.value]
              newVal.splice(input.value.findIndex(x => x === value), 1)
              input.onChange(newVal)
            }
          }}
        />
        {field.label}
      </Label>
    {meta.touched && meta.error}
    </Box>
  )
}

export default RfMultiCheckbox
import { darken, lighten, fade } from 'volcano/util/color'

const htmlFontSize = 16
const pxToRem = (value) => `${value / htmlFontSize}rem`
const round = (value) => Math.round(value * 1e5) / 1e5

const basecolor = {
  primary: '#153E53',
  accent: '#316113',
  light: '#F9FAFD',
  dark: '#222222',
  primaryDark: '#22374a',

  warning: '#F5A623',
  danger: '#D0021B',
  success: '#4BA87E',

  lightVariant: '#EDE8DE',
}

const coreColorKeys = ['primary', 'accent', 'light', 'dark', 'primaryDark']

const generateColor = base => {
  const result = { ...base }

  // const scale = [1, 0.8, 0.6, 0.4, 0.2]
  const lightScaleBase = 0.2
  const scale = [lightScaleBase * 5, lightScaleBase * 4, lightScaleBase * 3, lightScaleBase * 2, lightScaleBase]

  const darkScaleBase = 0.03
  const darkScale = [darkScaleBase * 5, darkScaleBase * 4, darkScaleBase * 3, darkScaleBase * 2, darkScaleBase]
  // const scale = [1, 0.8, 0.6, 0.4, 0.2].slice().reverse()

  Object.keys(base).filter(key => coreColorKeys.includes(key)).forEach(key => {
    const value = base[key]

    for (let i = 0; i <= 10; i++) {
      let modVal
      if (i < 5) modVal = darken(value, darkScale[i])
      else if (i > 5) modVal = lighten(value, scale[scale.length - (i - 5)])
      else modVal = value
      result[`${key}${i}`] = modVal
      result[`${key}${i}@10`] = fade(modVal, 1)
      result[`${key}${i}@8`] = fade(modVal, 0.8)
      result[`${key}${i}@6`] = fade(modVal, 0.6)
      result[`${key}${i}@4`] = fade(modVal, 0.4)
      result[`${key}${i}@2`] = fade(modVal, 0.2)
      result[`${key}${i}@0`] = fade(modVal, 0)

      if (modVal === value) {
        result[`${key}@10`] = fade(modVal, 1)
        result[`${key}@8`] = fade(modVal, 0.8)
        result[`${key}@6`] = fade(modVal, 0.6)
        result[`${key}@4`] = fade(modVal, 0.4)
        result[`${key}@2`] = fade(modVal, 0.2)
        result[`${key}@0`] = fade(modVal, 0)
      }
    }
  })

  return result
}

const colors = generateColor(basecolor)

const space = [0, 4, 8, 16, 32, 64, 128, 256, 512]

const breakpoints = [
  "576px",
  "768px",
  "992px",
  "1200px",
  "1366px"
]

const fontFamily = '"Reverb Main", "Helvetica", "Arial", sans-serif'
const headerFontFamily = '"Reverb Header", "Helvetica", "Arial", sans-serif'

const generateModularScale = (base, ratio) => ({
  xs: pxToRem(base / ratio / ratio),
  s: pxToRem(base / ratio),
  base: pxToRem(base),
  m: pxToRem(base * ratio),
  l: pxToRem(base * ratio * ratio),
  xl: pxToRem(base * ratio * ratio * ratio),
  xxl: pxToRem(base * ratio * ratio * ratio * ratio),
  xxxl: pxToRem(base * ratio * ratio * ratio * ratio * ratio),
  xxxxl: pxToRem(base * ratio * ratio * ratio * ratio * ratio * ratio),
})

const fontSizes = generateModularScale(13, 1.2)

const fontWeights = {
  extraLight: '200',
  light: '300',
  regular: '400',
  semiBold: '500',
  bold: '700',
}

const radii = ['2px', '4px', '6px', '8px']

const theme = {
  config: {
    baseAssetUrl: '/_asset/static'
  },

  colors,

  space,

  radii,

  breakpoints,

  fontFamily,
  headerFontFamily,
  fontSizes,
  fontWeights,

  borderRadius: '4px',

  input: {
    backgroundColor: colors.light7,
    backgroundColorHover: colors.light5,
    borderColorActive: colors.primary,
    borderColorPassive: colors.primary9,
  },

  runicSidebar: {
    bg: colors.primaryDark1,
    linkColor: colors.light1,
    linkColorActive: colors.accent7,
    linkColorDistinct: colors.accent7,
    linkColorHover: colors.accent7,
    markColor: colors.accent7,
    textColor: '#FFF',
    separatorColor: colors.primaryDark6,
    groupHeader: colors.accent7,
  }
}

export default theme
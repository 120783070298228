import React from 'react'

// Runic
import { Routes, Route, useMatch } from 'runic-aura/router'

import TenantHome from './TenantHome'
import TenantDetail from './TenantDetail'


const TenantManagement = props => {
  return (
    <Routes>
      <Route path={`r_detail/:unitId/*`} element={<TenantDetail/>}/>
      <Route path={`/`} element={<TenantHome/>}/>
    </Routes>
  )
}

export default TenantManagement
import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from 'emotion-theming'
import axios from 'axios'
import { useField } from 'react-final-form'
import AsyncSelect from 'react-select/async'
import { useFormState } from 'react-final-form'
import { setIn, getIn } from 'final-form'
import isArray from 'lodash/isArray'

// Volcano
import { Box, Flex } from 'volcano'
import Icon from 'volcano/components/Icon'

// Runic
import useAppState from 'runic/hooks/useAppState'
import ModelCreateButton from 'runic-aura/apps/model/components/ModelCreateButton'
import useFetchKey from 'runic/hooks/useFetchKey'

import { RunicFormContext } from './RfForm'


const RfLookupField = ({
  name,
  field,
  createComponent,
  ...rest
}) => {
  const theme = useTheme()
  const { input, meta } = useField(name, {
    // initialValue: defaultValue
  })

  const ctx = React.useContext(RunicFormContext)

  const Create = createComponent || ctx?.createComponents?.[field.modelName]

  const hasInitialValue = React.useRef()
  if (hasInitialValue.current === undefined) {
    if (input.value != '') hasInitialValue.current = true
    else hasInitialValue.current = null
  }
  const [isReady, isReadySet] = React.useState(input.value == '')
  const [value, valueSet] = React.useState()
  const [open, openSet] = React.useState()

  const { user } = useAppState()
  const rcTenantId = user && user.rc_tenant_id !== undefined ? user.rc_tenant_id : 1
  const headers = {
    'X-RUNIC-PLATFORM': useSelector((state) => state.core.config.runicPlatform)
  }

  React.useEffect(() => {
    if (input.value == '' || input.value.length === 0) return

    // console.log('*******', ctx?.entityMap, field.lookup._kind, input.value)
    if (field.isMulti) {
      const values = []
      input.value?.forEach(val => {
        const existing = ctx?.entityMap?.[field.lookup._kind]?.[val]
        if (existing) {
          values.push({
            value: existing.id,
            label: existing.name,
            item: existing
          })
        }
      })
      valueSet(values)
      isReadySet(true)
      return
    } else {
      const existing = ctx?.entityMap?.[field.lookup._kind]?.[input.value]
      if (existing) {
        valueSet({
          value: existing.id,
          label: existing.name,
          item: existing
        })
        isReadySet(true)
        return
      }
    }

    let params = {}

    if (ctx?.sourceCtx) {
      params = {
        ...params,
        ...ctx?.sourceCtx
      }
    }
    console.log('***', params, ctx?.sourceCtx)

    const fetch = async () => {
      const res = await axios.get(`/_api/runic/source/${field.source}`, { params: { rcTenantId, id: input.value, ...field.lookup, ...params }, headers }).catch(err => err)
      if (!res || res.err) {
        // console.log('err')
      } else {
        // console.log('res', res)
        valueSet({
          value: res.data.items[0].id,
          label: res.data.items[0].item.name,
          item: res.data.items[0].item
        })
        isReadySet(true)
      }
    }
    fetch()

  }, [])

  // console.log('kkk', value)

  React.useEffect(() => {
    // console.log('kekekek', value)
    if (value) {
      if (ctx?.entityMap && !ctx?.entityMap[field.lookup._kind]) ctx.entityMap[field.lookup._kind] = {}

      if (isArray(value)) {
        if (ctx?.entityMap) value.forEach(v => ctx.entityMap[field.lookup._kind][v.value] = v.item)
        input.onChange(value.map(v => v.value))
      } else {
        if (ctx?.entityMap) ctx.entityMap[field.lookup._kind][value.value] = value.item
        input.onChange(value.value)
      }

      openSet(false)
    } else {
      input.onChange(value)
      openSet(false)
    }
  }, [value])

  if (!isReady) return null

  return (
    <Flex sx={{
      alignItems: 'center'
    }}>
      <Box sx={{
        flex: '1 1 0'
      }}>
        <Lookup field={field} value={value} onChange={valueSet} ctx={ctx} />
      </Box>

      {Create && <Box sx={{
        flex: '0 0 40px',
        textAlign: 'center'
      }}>
        <Create field={field} onSuccess={rxBlock => {
          valueSet({
            value: rxBlock.id,
            label: rxBlock.name,
            item: rxBlock
          })
        }} />
      </Box>}
    </Flex>
  )
}

const Lookup = ({
  field,
  value,
  onChange,
  ctx
}) => {
  const theme = useTheme()
  const { user } = useAppState()
  const rcTenantId = user && user.rc_tenant_id !== undefined ? user.rc_tenant_id : 1
  const headers = {
    'X-RUNIC-PLATFORM': useSelector((state) => state.core.config.runicPlatform)
  }

  const formState = useFormState({
    subscription: {
      values: true
    }
  })

  let params = {}
  if (field.params) {
    Object.keys(field.params).forEach(key => {
      const param = field.params[key]
      if (param?._kind === 'dynamic') {
        if (param.valueSource === 'form') {
          params[key] = getIn(formState.values, param.valueKey)
        } else if (param.valueSource === 'group') {
          params[key] = getIn(formState.values, `${field.parentPath}.${param.valueKey}`)
        }
      } else {
        params[key] = param
      }
    })
  }

  if (ctx?.sourceCtx) {
    params = {
      ...params,
      ...ctx?.sourceCtx
    }
  }

  const key = useFetchKey(Object.values(params))

  return (

    <AsyncSelect
      key={key}
      // onChange={(props) => {
      //   field?.isMulti ? input.onChange(props?.map(x => x.value)) : input.onChange(props?.value)
      // }}
      // value={options.find(x => x.value === input.value)}
      onChange={onChange}
      value={value}
      isMulti={field.isMulti}
      closeMenuOnSelect={!field.isMulti}
      // defaultOptions={field.defaultOptions}
      defaultOptions
      loadingMessage={field.loadingMessage}
      noOptionsMessage={field.noOptionsMessage}
      defaultValue={field.defaultValue}
      tabSelectsValue={false}
      loadOptions={(input, cb) => {
        // let r = await axios.get(`http://${url.host}/_api/runic/source/BookCommunity.User.status`, {headers}).catch(err => ({err}))
        // r = r.err ? null : r.data
        // return r
        axios.get(`/_api/runic/source/${field.source}`, { params: { rcTenantId, value: input, ...field.lookup, ...params }, headers }).then(r => {
          field.defaultOptions ? cb([...field.defaultOptions, ...r.data?.items?.map(x => ({
            value: x.id,
            label: x.item.name,
            item: x.item
          }))]) : cb(r.data?.items?.map(x => ({
            value: x.id,
            label: x.item.name,
            item: x.item
          })))
        })
        // r = r.err ? null : r.data
        // return r
      }}
      // options={options}
      cacheOptions
      placeholder={field.placeholder || 'Seç...'}
      styles={{
        control: (provided, state) => ({
          ...provided,
          borderColor: theme.colors.primary,
          paddingTop: 4,
          paddingBottom: 4,
          ...field?.styles?.control,
        }),
        menu: (provided, state) => ({
          ...provided,
          zIndex: 99,
        })
      }} />
  )
}

export default RfLookupField
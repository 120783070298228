import React from 'react'

// Volcano
import useFileInputElement from 'volcano/hooks/useFileInputElement'

const useFileDialog = ({
  acceptedFileKinds,
  onAdd,
  multiple = false
}) => {
  const cb = React.useCallback(e => {
    console.log('u')
    console.log(e.path[0].files)
    onAdd(e.path[0].files)
  }, [onAdd])
  const inputElement = useFileInputElement(acceptedFileKinds, cb, {multiple})
  const openFileDialog = React.useCallback((e) => {
    e.preventDefault()
    inputElement.dispatchEvent(new MouseEvent("click"))
  }, [inputElement])

  return openFileDialog
}

export default useFileDialog
import React from 'react'

// Vendor
import * as changeCase from 'volcano/util/text'
import { useSelector } from 'react-redux'
import dotProp from 'dot-prop'

// Rncui
import { relatedEntitySelector, getRenderDataForField, getColumnsForModel } from '../util'
import RunicLink from 'runic-aura/components/RunicLink'

// Reverb
import { CellWrapper } from 'volcano/components/WindowedTable'
import Icon from 'volcano/components/Icon'
import Text from 'volcano/components/Text'
import DateTime from 'volcano/components/DateTime'


export const RelatedEntityCell = ({entity, columnAttrPath, columnFieldData, column, identifier, modelDataMap}) => {
  const [targetFieldData, columnPath, searchPath, modelList] = React.useMemo(() => getRenderDataForField(modelDataMap, columnFieldData, column.modelName, columnAttrPath), [modelDataMap, columnFieldData, column.modelName, columnAttrPath])
  const id = entity[columnFieldData.name]

  const targetEntity = useSelector(state => relatedEntitySelector(state, modelList, columnPath, id))

  return <EntityCell entity={targetEntity} columnAttrPath={modelList[modelList.length-1].targetPath.replace(`${targetFieldData.name}.`, '')} columnFieldData={targetFieldData} column={column} identifier={identifier} related={true}/>
}

export const EntityCell = ({entity, columnAttrPath, columnFieldData, column, identifier, related}) => {
  let attr
  if (entity) {
    if (columnFieldData.model_attr_kind == 'JSON') attr = dotProp.get(entity[columnFieldData.name], columnAttrPath)
    else attr = entity[columnAttrPath]
  }

  return <BaseEntityCell identifier={identifier} entity={entity} attr={attr} columnFieldData={columnFieldData} column={column} related={related}/>
}


export const BaseEntityCell = props => {
  const { entity, attr, columnFieldData, column, identifier, related } = props

  let content = attr

  if (attr !== undefined) {
    if (columnFieldData.model_attr_kind == 'DateTime') content = <DateTime data={content} time={columnFieldData.options && columnFieldData.options.showTime}/>
    else if (columnFieldData.model_attr_kind == 'Boolean') content = attr ? <Icon name='check' p={0}/> : <Icon name='circle-remove' p={0}/>
    else if (columnFieldData.options && columnFieldData.options.translate) content = <Text textCase='title' id={`enum.${changeCase.snake(content)}`} {...columnFieldData.options}/>
    else if (columnFieldData.model_attr_kind == 'JSON') {
      if (attr === true || attr === false) content = attr ? <Icon name='check' p={0}/> : <Icon name='circle-remove' p={0}/>
      else if (attr === 'None') content = ''
    }

    if (!related && (columnFieldData.name == identifier || `self.${columnFieldData.name}` == identifier)) {
      if (column.detailOnClick) {
        content = <a onClick={() => column.detailOnClick(entity)}>{content}</a>
      } else if (!column.activeModelData && column.detailUrlPart) {
        content = <RunicLink to={`${column.detailUrlPart}/${entity.id}/`}>{content}</RunicLink>
      } else if (!column.activeModelData) {
        content = <RunicLink to={`r_detail/${entity.id}/`}>{content}</RunicLink>
      } else if (column.activeModelData && column.activeModelData.hasSubLayout && !column.activeModelData.subDetail) {
        content = <RunicLink to={`${column.modelName}/r_detail/${entity.id}/`}>{content}</RunicLink>
      }
      // else content = <RunicLink to={`?rnrdr=1&ident=${column.identifier}&id=${entity.id}`}>{content}</RunicLink>
    } else if (column.columnFieldData.is_relationship) {
      // content = <RunicLink to={`?rnrdr=1&ident=${column.columnFieldData.target_model_name}&id=${entity.id}`}>{content}</RunicLink>
    } else if (column.columnFieldData.is_reverse_relationship) {
      content = entity[column.fieldAttrPath].map(x => x.name).join(', ')
    }

    // if (columnFieldData.name == 'id') content = `#${content}`
  }

  // if
  return (
    <CellWrapper colWidth={column.colWidth} flexGrow={column.flexGrow}>
      {content}
    </CellWrapper>
  )
}

export const RelatedEntityField = ({entity, columnAttrPath, columnFieldData, column, identifier, modelDataMap}) => {
  const [targetFieldData, columnPath, searchPath, modelList] = React.useMemo(() => getRenderDataForField(modelDataMap, columnFieldData, column.modelName, columnAttrPath), [modelDataMap, columnFieldData, column.modelName, columnAttrPath])
  const id = entity[columnFieldData.name]

  const targetEntity = useSelector(state => relatedEntitySelector(state, modelList, columnPath, id))

  return <EntityField entity={targetEntity} columnAttrPath={modelList[modelList.length-1].targetPath.replace(`${targetFieldData.name}.`, '')} columnFieldData={targetFieldData} column={column} identifier={identifier} related={true}/>
}

export const EntityField = ({entity, columnAttrPath, columnFieldData, column, identifier, related}) => {
  let attr
  if (entity) {
    if (columnFieldData.model_attr_kind == 'JSON') attr = dotProp.get(entity[columnFieldData.name], columnAttrPath)
    else attr = entity[columnAttrPath]
  }

  return <BaseEntityField identifier={identifier} entity={entity} attr={attr} columnFieldData={columnFieldData} column={column} related={related}/>
}


export const BaseEntityField = props => {
  const { entity, attr, columnFieldData, column, identifier, related } = props

  let content = attr

  if (attr) {
    if (columnFieldData.model_attr_kind == 'DateTime') content = <DateTime data={content} time={columnFieldData.options && columnFieldData.options.showTime}/>
    else if (columnFieldData.model_attr_kind == 'Boolean') content = attr ? <Icon name='check' p={0}/> : <Icon name='remove' p={0}/>
    else if (columnFieldData.options && columnFieldData.options.translate) content = <Text textCase='title' id={`enum.${changeCase.snake(content)}`} {...columnFieldData.options}/>
    else if (columnFieldData.model_attr_kind == 'JSON') {
      if (attr === true || attr === false) content = attr ? <Icon name='check' p={0}/> : <Icon name='remove' p={0}/>
      else if (attr === 'None') content = ''
    }

    if (!related && columnFieldData.name == identifier) content = <RunicLink to={`r_detail/${entity.id}/`}>{content}</RunicLink>

    if (columnFieldData.name == 'id') content = `#${content}`
  }

  // if
  return (
    <div>
      {content}
    </div>
  )
}

// export const StandaloneRelatedEntityField = ({entity, columnAttrPath, columnFieldData, column, identifier, modelDataMap}) => {
export const StandaloneRelatedEntityField = ({modelData, entity, columnAttrPath}) => {
  const modelDataMap = useSelector(state => state.model.models)
  const columns = getColumnsForModel([columnAttrPath], modelData)
  const modelName = modelData.name
  const columnFieldData = columns[0].columnFieldData

  const [targetFieldData, columnPath, searchPath, modelList] = React.useMemo(() => getRenderDataForField(modelDataMap, columnFieldData, modelName, columnAttrPath), [modelDataMap, columnFieldData, modelName, columnAttrPath])
  const id = entity[columnFieldData.name]

  const targetEntity = useSelector(state => relatedEntitySelector(state, modelList, columnPath, id))

  return <EntityField entity={targetEntity} columnAttrPath={modelList[modelList.length-1].targetPath.replace(`${targetFieldData.name}.`, '')} columnFieldData={targetFieldData} column={{}}/>
}

export const EntityData = ({modelData, id, children}) => {
  const entity = useSelector(state => state.orm.entity[changeCase.camel(modelData.name)][id])
  return children(entity)
}
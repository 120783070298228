import React from 'react'

// Vendor
import { useSelector } from 'react-redux'

// Reverb
import Icon from 'volcano/components/Icon'
import Text from 'volcano/components/Text'
import { Menu, MenuItem } from 'volcano/components/Menu'
import { Flex, Box } from 'volcano'

// Runic
import useDirector from 'runic/hooks/useDirector'

// RunicAura
import { Link, useMatch } from 'runic-aura/router'
import { SidebarWrapper, SidebarSeparator, SidebarItem, SidebarItemLink } from 'runic-aura/apps/core/components/RunicSidebar'
import RunicDomainMenu from './RunicDomainMenu'


const defaultConfig = {
  extended: true
}

const SidebarZoneMenu = ({
  activeZone,
  baseUrl
}) => {
  const zones = useSelector(state => state.runicAura.zones)
  const hasMenu = (activeZone.domains && activeZone.domains.length > 1) || (Object.keys(zones).length > 1)
  if (!hasMenu) return null

  return (
    <Menu anchor={<Box sx={{
      color: '#FFF'
    }}><Icon name='chevron-down' /></Box>} placement='bottom-start'>
      <SidebarZoneMenuContent activeZone={activeZone} zones={zones} baseUrl={baseUrl}/>
    </Menu>
  )

}

const SidebarZoneMenuContent = ({
  activeZone,
  zones,
  onClose,
  baseUrl
}) => {
  console.log('---', baseUrl)
  console.log(zones)
  return (
    <Box sx={{p: 3}}>
      {activeZone.domains.length > 1 && Object.keys(activeZone.domains).map((domainKey, domainDx) => (
        <div key={domainDx}>
          <Link to={`${baseUrl ? `${baseUrl}/` : ''}${activeZone.domains[domainKey].indexPath}/`}>{activeZone.domains[domainKey].name}</Link>
        </div>
      ))}

      {/* <Text b dBotS strong>Çalışma Alanları</Text> */}
      <Text fontWeight='bold' block>Çalışma Alanları</Text>
      {Object.values(zones).map((zone, zoneDx) => (
        <div key={zoneDx}>
          {console.log(zone)}
          <Link to={`${baseUrl ? `${baseUrl}/` : ''}${zone.indexPath ? zone.indexPath + '/' : '' }`} onClick={onClose}>{zone.name}</Link>
        </div>
      ))}
    </Box>
  )
}


const RncSidebarZoneDefault = ({
  baseUrl
}) => {
  const director = useDirector()
  const uiConfig = useSelector(state => state.runicAura.config?.component?.RncSidebar) || defaultConfig

  const activeZone = useSelector(state => state.runicAura.zones[state.runicAura.activeZoneName])
  const activeDomainName = useSelector(state => state.runicAura.activeDomainName)
  const activeDomain = activeZone && activeZone.domains.find(domain => domain.name == activeDomainName)

  const DomainMenu = activeDomain && director.directory.runicAura.menuHandlers && director.directory.runicAura.menuHandlers[activeDomain.kind] || RunicDomainMenu

  if (!activeZone || !activeDomain) return null

  return (
    <SidebarWrapper extended={uiConfig.extended}>
      <Flex mb={-10} sx={{
        alignItems: 'center'
      }}>
        <Box width={'100%'}>
          <SidebarItem icon={activeDomain.icon} name={activeDomain.displayName} distinct extended={uiConfig.extended} />
        </Box>
        <Box mr={2}>
          <SidebarZoneMenu activeZone={activeZone} baseUrl={baseUrl} />
        </Box>
      </Flex>

      <SidebarSeparator extended={uiConfig.extended} />

      <DomainMenu uiConfig={uiConfig} activeZone={activeZone} activeDomain={activeDomain} />
    </SidebarWrapper>
  )
}

export default RncSidebarZoneDefault
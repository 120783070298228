import React from 'react'

// Vendor
import DayPicker from 'react-day-picker'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { DateUtils } from 'react-day-picker'
import { useField } from 'react-final-form'
import dateFnsFormat from 'date-fns/format'
import dateFnsParse from 'date-fns/parse'
import { tr } from 'date-fns/locale'
// Volcano
import { Box, Flex } from 'volcano'

const Input = props => <Box as='input' sx={{
  ...props.sx,
  width: '100%',
  px: 2,
  py: 2
}} {...props} />

function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale })
  if (DateUtils.isDate(parsed)) {
    return parsed
  }
  return undefined
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale })
}


const MONTHS = [
  'Ocak',
  'Şubat',
  'Mart',
  'Nisan',
  'Mayıs',
  'Haziran',
  'Temmuz',
  'Ağustos',
  'Eylül',
  'Ekim',
  'Kasım',
  'Aralık',
]
const WEEKDAYS_LONG = [
  'Pazartesi',
  'Salı',
  'Çarşamba',
  'Perşembe',
  'Cuma',
  'Cumartes',
  'Pazar',
]
const WEEKDAYS_SHORT = ['Pzt', 'Sl', 'Çr', 'Pr', 'Cu', 'Ct', 'Pz']

const RfDateField = ({ name }) => {
  const { input, meta } = useField(name)
  return (
    <Box sx={{
      color: '#000'
    }}>
    <DayPickerInput
      value={input.value}
      format='dd.MM.yyyy'
      formatDate={formatDate}
      parseDate={parseDate}
      onDayChange={input.onChange}
      placeholder='Tarih'
      component={Input}
      style={{
        display: 'block'
      }}
      dayPickerProps={{
        locale: tr,
        months: MONTHS,
        weekdaysLong: WEEKDAYS_LONG,
        weekdaysShort: WEEKDAYS_SHORT,
        firstDayOfWeek: 1,
      }} />
    </Box>
  )
}

export default RfDateField
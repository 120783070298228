import React from 'react'


const useFileInputElement = (accept, callback, options = {}) => {
  const {multiple = false} = options
  const inputRef = React.useRef()
  if (!process.browser) return

  if (!inputRef.current) {
    inputRef.current = document.createElement("input")
    const inputElement = inputRef.current
    inputElement.type = "file"
    inputElement.accept = accept
    inputElement.multiple = multiple

    inputElement.addEventListener("change", callback)
  }

  return inputRef.current
}

export default useFileInputElement
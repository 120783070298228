import React from 'react'

// Vendor
import { useSelector } from 'react-redux'

// Rncui
import { Routes, Route, useMatch } from 'runic-aura/router'
import { useRncActivate } from 'runic-aura/apps/uitree/hooks/tree'

// Runic
import useDirector from 'runic/hooks/useDirector'


const RncModelUnit = ({
  domain,
  item,
  profileName = 'Default'
}) => {
  const unit = useSelector(state => state.runicAura.units[item.unitName])
  useRncActivate('unit', unit)
  const director = useDirector()

  const profile = unit.profiles[profileName]

  const HomeComponent = profile.pages?.home && director.components[profile.pages.home.name]
  const DetailComponent = profile.pages?.detail && director.components[profile.pages.detail.name]

  if (!HomeComponent || !DetailComponent) {
    console.warn('Home or detail not found', HomeComponent, DetailComponent, profile.pages.home.name, profilepages.detail.name)
    return null
  }

  return (
    <Routes>
      <Route path={`/`} element={<HomeComponent unit={unit} profile={profile} />}/>
      <Route path={`r_detail/:unitId/*`} element={<DetailComponent unit={unit} profile={profile} backUrl={`/`} />}/>
    </Routes>
  )
}

export default RncModelUnit
import React from 'react'

// Riva
import { uuidv4 } from 'volcano/util'
import useFileInputElement from 'volcano/hooks/useFileInputElement'

const useFileSelector = (options = {}) => {
  const {
    maxImageSize = 1,
    maxVideoSize = 1,
    multiple = false,
    acceptedFileKinds
  } = options
  const [state, stateSet] = React.useState({
    files: [],
    rejectedFiles: [],
    uploadingFiles: [],
    errorFiles: [],
    uploadedFiles: [],
  })

  const latestState = React.useRef(null)
  latestState.current = state

  if (!process.browser) return

  const createFilePreview = (file) => window.URL.createObjectURL(file)
  const destroyFilePreview = (file) => window.URL.revokeObjectURL(file.preview)

  const createFilePreviews = (files) => files.forEach(file => {if (!file.preview) file.preview = createFilePreview(file)})
  const destroyFilePreviews = (files) => files.forEach(file => {if (file.preview) destroyFilePreview(file);console.log(file)})

  React.useEffect(() => {
    return () => {
      destroyFilePreviews(latestState.current.files)
      destroyFilePreviews(latestState.current.rejectedFiles)
      destroyFilePreviews(latestState.current.uploadingFiles)
      destroyFilePreviews(latestState.current.errorFiles)
      destroyFilePreviews(latestState.current.uploadedFiles)
    }
  }, [])

  const checkFile = (file) => {
    // FIXME: check file upload
    // console.log(file.type)
    // if (file.type.indexOf('image') != -1) {
    //   if (file.size > maxImageSize * 1024 * 1024) {
    //     file.error = {
    //       id: 'MAX_FILE_SIZE',
    //       values: {
    //         size: file.size,
    //         max: maxImageSize  * 1024 * 1024
    //       }
    //     }
    //   }
    // } else if (file.type.indexOf('video') != -1) {
    //   if (file.size > maxVideoSize * 1024 * 1024) {
    //     file.error = {
    //       id: 'MAX_FILE_SIZE',
    //       values: {
    //         size: file.size,
    //         max: maxVideoSize * 1024 * 1024
    //       }
    //     }
    //   }
    // } else {
    //   file.error = 'INVALID_FILE_KIND'
    // }

    return file
  }

  const addFiles = (e) => {
    const filesDict = e.path[0].files
    const acceptedFiles = []
    const rejectedFiles = []

    for (let i = 0; i < filesDict.length; i++) {
      const file = checkFile(filesDict[i])
      file.displayName = file.name

      if (!file.error) {
        file.preview = createFilePreview(file)
        file.id = uuidv4()
        acceptedFiles.push(file)
      } else {
        rejectedFiles.push(file)
      }
    }

    stateSet(curr => {
      return ({
        ...curr,
        files: [...curr.files, ...acceptedFiles],
        rejectedFiles: [...curr.rejectedFiles, ...rejectedFiles],
        // key: this.state.key + 1
      })
    });
  }

  // const removeFile = (index) => {
  //   this.destroyFilePreview(this.state.files[index])
  //   this.setState(update(this.state, {files: {$splice: [[index, 1]]}}))
  // }
  // const changeFile = (index, valName, val) => this.setState(update(this.state, {files: {[index]: {[valName]: {$set: val}}}}))
  const reset = () => {
    console.log('reset')
    destroyFilePreviews(state.files)
    destroyFilePreviews(state.uploadingFiles)
    stateSet(curr => {
      return ({
        ...curr,
        files: [],
        uploadingFiles: [],
        uploadedFiles: [],
      })
    })
  }

  const start = (files) => {
    console.log('start')
    stateSet(curr => {
      return ({
        ...curr,
        files: [],
        uploadingFiles: files,
      })
    })
  }

  const success = (fileId) => {
    console.log('success')
    stateSet(curr => {
      const fileDx = curr.uploadingFiles.findIndex(x => x.id === fileId)
      if (fileDx === -1) return curr

      const uploading = curr.uploadingFiles.slice()
      const [file] = uploading.splice(fileDx, 1)
      return ({
        ...curr,
        uploadingFiles: uploading,
        uploadedFiles: [...curr.uploadedFiles, file]
      })
    })
  }

  const error = (file) => {
    console.log('error')
    stateSet(curr => {
      const fileDx = curr.uploadingFiles.findIndex(x => x.id === file.id)
      if (fileDx === -1) return curr

      const uploading = curr.uploadingFiles.slice()
      uploading.splice(fileDx, 1)
      return ({
        ...curr,
        uploadingFiles: uploading,
        errorFiles: [...curr.errorFiles, file]
      })
    })
  }

  const retry = (file) => {
    stateSet(curr => {
      const fileDx = curr.errorFiles.findIndex(x => x.id === file.id)
      if (fileDx === -1) return curr

      const uploading = curr.errorFiles.slice()
      uploading.splice(fileDx, 1)
      return ({
        ...curr,
        errorFiles: uploading,
        uploadingFiles: [...curr.uploadingFiles, file]
      })
    })
  }

  const removeRejectedFiles = () => stateSet({...state, rejectedFiles: []})

  const inputElement = useFileInputElement(acceptedFileKinds, addFiles, {multiple})
  const openFileDialog = React.useCallback(() => inputElement.dispatchEvent(new MouseEvent("click")), [inputElement])

  return [openFileDialog, state, reset, start, error, retry, success]
}

export default useFileSelector
import React from 'react'

// Volcano
import { Flex, Box } from 'volcano'
import { useFormState, useForm } from 'react-final-form'

// Blockreactor
import RfField from './RfField'


const RfRecurrenceField = ({
  field
}) => {
  const formState = useFormState()
  const values = formState.values

  const frequencyOptions = [
    // {
    //   label: 'Saniye',
    //   value: 'SECONDLY',
    // },

    // {
    //   label: 'Dakika',
    //   value: 'MINUTELY',
    // },

    // {
    //   label: 'Saat',
    //   value: 'HOURLY',
    // },

    {
      label: 'Gün',
      value: 'DAILY',
    },

    {
      label: 'Hafta',
      value: 'WEEKLY',
    },

    {
      label: 'Ay',
      value: 'MONTHLY',
    },

    // {
    //   label: 'Yıl',
    //   value: 'YEARLY',
    // },
  ]

  const byWeekDayOptions = [
    {
      label: 'Pazartesi',
      value: 'MO'
    },

    {
      label: 'Salı',
      value: 'TU'
    },

    {
      label: 'Çarşamba',
      value: 'WD'
    },

    {
      label: 'Perşembe',
      value: 'TH'
    },

    {
      label: 'Cuma',
      value: 'FR'
    },

    {
      label: 'Cumartesi',
      value: 'ST'
    },

    {
      label: 'Pazar',
      value: 'SU'
    },
  ]

  let form

  if (field.mode === 'simple') {
    return (
      <Box>
        <RfField field={{
        name: `${field.name}.dates`,
        label: 'Tarihler',
        kind: 'dateTime',
        list: true
      }}/>
      </Box>
    )
  }

  if (values[field.name]?.recurrenceKind == 'RRULE') {
    form = (
      <>
      <Flex sx={{
        mx: -2
      }}>
        <Box sx={{
          flex: '1 1 0',
          mx: 2
        }}>
        <RfField field={{
        name: `${field.name}.frequency`,
        label: 'Frekans',
        kind: 'dropdown',
        options: frequencyOptions
      }}/>
        </Box>

        <Box sx={{
          flex: '1 1 0',
          mx: 2
        }}>
        <RfField field={{
        name: `${field.name}.interval`,
        label: `Her ${(values[field.name]?.interval && (values[field.name]?.interval > 1 ? values[field.name]?.interval : ' ')) || 'x'} ${frequencyOptions.find(x => x.value === values[field.name]?.frequency)?.label || ''} da bir`,
        kind: 'number',
      }}/>
        </Box>
      </Flex>

      <RfField field={{
        name: `${field.name}.byWeekDay`,
        label: 'Haftanın günleri',
        kind: 'dropdown',
        options: byWeekDayOptions,
        isMulti: true
      }}/>

<RfField field={{
        name: `${field.name}.count`,
        label: 'Tekrarlama Sayısı',
        kind: 'number'
      }}/>
      </>
    )
  } else if (values[field.name]?.recurrenceKind == 'MANUAL') {
    form = (
      <>
      <RfField field={{
        name: `${field.name}.dates`,
        label: 'Tarihler',
        kind: 'dateTime',
        list: true
      }}/>
      </>
    )
  }

  return (
    <Box>
      <RfField field={{
        name: `${field.name}.recurrenceKind`,
        label: 'Tekrarlama Türü',
        kind: 'dropdown',
        options: [
          {
            label: 'Tarihleri tek tek belirle',
            value: 'MANUAL',
          },

          {
            label: 'Tarihleri programla',
            value: 'RRULE_CUSTOM',
          },

          // {
          //   label: 'Tarihleri programla',
          //   value: 'RRULE',
          // }
        ]
      }}/>

      {form}
    </Box>
  )
}

export default RfRecurrenceField
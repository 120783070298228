// Vendor
import * as changeCase from "change-case"
import { takeEvery, call, take, put, select } from 'redux-saga/effects'
import { normalize } from 'normalizr'

// Riva
import { runicUploadRequest, callCallbacks } from 'runic/core/sagaHelpers'

// Self
import selfActions from './actions'


function* startUpload(action) {
  const { files, elementName, actionName, modelName, ...restPayload } = action.payload

  action.callbacks.start(files)

  for (let i = files.length - 1; i >= 0; i--) {
    const file = files[i]

    const uploadData = {...restPayload}
    uploadData.fileId = file.id
    uploadData.displayName = file.displayName
    file.uploadData = uploadData

    yield put({
      type: selfActions.RCR_UPLOAD,
      payload: {file, uploadData, elementName, actionName, modelName},
      meta: {
        ...action.meta,
        status: 'START'
      },
      callbacks: action.callbacks
    })
  }
}

function* retryUpload(action) {
  const { file, uploadData, elementName, actionName, modelName } = action.payload

  yield put({
    type: selfActions.RCR_UPLOAD,
    payload: {file, uploadData, elementName, actionName, modelName},
    meta: {
      ...action.meta,
      status: 'START'
    },
    callbacks: action.callbacks
  })
}

function* upload(action) {
  const { file, uploadData, elementName, actionName, modelName } = action.payload

  const actionPath = `${elementName}.${actionName}`
  const data = new FormData()
  data.append('file', file)
  data.append('data', JSON.stringify(uploadData))

  const callbacks = {
    onUploadProgress: (e) => {
      const percentCompleted = Math.round( (e.loaded * 100) / e.total );
      action.callbacks.progress({
        id: file.id,
        progress: percentCompleted,
        modelName
      })
    }
  }

  try {
    const apiResponse = yield call(runicUploadRequest, actionPath, data, callbacks)
    let payload = {
      ...apiResponse.data,
      modelName
    }

    if (apiResponse.data && apiResponse.data._rc_entity) {
      const entitySchemasBySnakeCaseName = yield select((state) => state.model.entitySchemasBySnakeCaseName)

      const entityData = normalize(apiResponse.data._rc_entity, entitySchemasBySnakeCaseName)
      delete payload._rc_entity
      payload['rcEntity'] = entityData
    }
    payload.fileId = file.id
    payload.progress = 0

    yield put({
      type: selfActions.RCR_UPLOAD,
      payload: payload,
      meta: {
        ...action.meta,
        status: 'SUCCESS'
      }
    })

    callCallbacks(action, 'SUCCESS', payload)
  } catch (error) {
    if (error.response) {
      const payload = {
        ...action.payload,
        _error: error.response,
        file,
      }
      yield put({
        type: selfActions.RCR_UPLOAD,
        payload,
        meta: {
          ...action.meta,
          status: 'ERROR'
        }
      })
      callCallbacks(action, 'ERROR', payload)
    } else {
      // FIXME: log error and continue in PROD
      throw error
    }
  }
}

function* uploadNew(action) {
  let actionCamelName
  const actionComponent = action.meta.component
  if (action.type.includes('/')) {
    const actionTypeSplit = action.type.split('/')
    const actionName = actionTypeSplit[actionTypeSplit.length - 1]
    actionCamelName = changeCase.pascalCase(actionName)
  } else {
    actionCamelName = changeCase.pascalCase(action.type)
  }
  const actionPath = `${actionComponent}.${actionCamelName}`

  const { formData, modelName } = action.payload

  const data = formData
  console.log('***', data)

  const callbacks = {
    onUploadProgress: (e) => {
      const percentCompleted = Math.round( (e.loaded * 100) / e.total );
      action?.callbacks?.progress({
        // id: file.id,
        progress: percentCompleted,
        modelName
      })
    }
  }

  try {
    const apiResponse = yield call(runicUploadRequest, actionPath, data, callbacks)
    let payload = {
      ...apiResponse.data,
      modelName
    }

    if (apiResponse.data && apiResponse.data._rc_entity) {
      const entitySchemasBySnakeCaseName = yield select((state) => state.model.entitySchemasBySnakeCaseName)

      const entityData = normalize(apiResponse.data._rc_entity, entitySchemasBySnakeCaseName)
      delete payload._rc_entity
      payload['rcEntity'] = entityData
    }
    payload.progress = 0

    yield put({
      type: action.type,
      payload: payload,
      meta: {
        ...action.meta,
        status: 'SUCCESS'
      }
    })

    callCallbacks(action, 'SUCCESS', payload)
  } catch (error) {
    if (error.response) {
      const payload = {
        ...action.payload,
        _error: error.response,
        // file,
      }
      yield put({
        type: action.type,
        payload,
        meta: {
          ...action.meta,
          status: 'ERROR'
        }
      })
      callCallbacks(action, 'ERROR', payload)
    } else {
      // FIXME: log error and continue in PROD
      throw error
    }
  }
}

export function* watchStartUpload() {
  yield takeEvery((action) => action.type == selfActions.RCR_START_UPLOAD, startUpload)
}

export function* watchUpload() {
  yield takeEvery((action) => action.type == selfActions.RCR_UPLOAD && action.meta.status == 'START', upload)
}

export function* watchUploadNew() {
  yield takeEvery((action) => action.meta.kind == 'RCR_UPLOAD' && action.meta.status == 'START', uploadNew)
}

export function* watchRetryUpload() {
  yield takeEvery((action) => action.type == selfActions.RCR_RETRY_UPLOAD && action.meta.status == 'START', retryUpload)
}

export default [watchStartUpload, watchUpload, watchUploadNew, watchRetryUpload]
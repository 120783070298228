import React from 'react'

// Vendor
import { useSelector, useDispatch } from 'react-redux'

// Runic
import coreActions from 'runic/systems/core/actions'

// RunicAura
import { Routes, useParams, Route, useHref } from 'runic-aura/router'
import RunicZone from 'runic-aura/apps/uitree/pages/RunicZone'
import RunicRouteManager from '../components/RunicRouteManager'


const RunicTenantIndex = props => {
  const [isReady, setIsReady] = React.useState(false)
  const params = useParams()
  const zones = useSelector(state => state.runicAura.zones)
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(coreActions.rcrRivaTenantSetup({ rcTenantId: params.rcTenantId }, {
      success: () => setIsReady(true),
      // FIXME: add error callback, display error page
    }))
  }, [])

  const href = useHref('')

  if (!isReady) return null

  return (
    <>
      <RunicRouteManager />
      <Routes>
        {Object.values(zones).map((zone, dx) => (
          <Route key={dx} path={`${zone.path}/*`} element={<RunicZone zone={zone} baseUrl={href} />}/>
        ))}
      </Routes>
    </>
  )
}

export default RunicTenantIndex

import React from 'react'

// Vendor
import { useField } from 'react-final-form'

// Volcano
import { Box, Flex } from 'volcano'
import { Checkbox, Label } from 'volcano/components/Checkbox'


const RfBooleanField = ({
  name,
  field,
}) => {
  const { input, meta } = useField(name, {
    validate: (value) => {
      let error
      if (field?.required && (
        value === undefined ||
        value === null ||
        value == ''
      )) {
        error = 'Gerekli'
      }
      return error
    },
    type: 'checkbox'
  })
  return (
    <Box sx={{
      width: '100%'
    }}>
    <Label>
        <Checkbox
          {...input}
        />
        {field.label}
      </Label>
    {meta.touched && meta.error}
    </Box>
  )
}

export default RfBooleanField
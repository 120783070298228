import React from 'react'

// Volcano
import usePopper from 'volcano/hooks/usePopper'

export const ModalContext = React.createContext()

const useModal = ({ placement, isOpen, setIsOpen, ...rest }) => {
  const referenceNode = process.browser && document.body
  const popperNode = React.useRef(null)
  const arrowNode = React.useRef(null)
  // const [isOpen, setIsOpen] = React.useState(false)
  // const { style, update, arrowStyle } = usePopper({
  //   referenceNode, popperNode, arrowNode, isOpen, placement, modifiers: {
  //     flip: {
  //       behavior: ['left', 'right', 'top', 'bottom']
  //     },
  //     // preventOverflow: { enabled: false },
  //     // positionFixed: true,
  //     preventOverflow: {
  //       // boundariesElement: 'window'
  //     },
  //   }, ...rest
  // })

  const { style, update, arrowStyle } = usePopper({
    referenceNode, popperNode, arrowNode, isOpen, placement, modifiers: [
      {
        name: 'flip',
        enabled: true,
        options: {
          // fallbackPlacements: [ 'left', 'right', 'top', 'bottom'],
          // rootBoundary: 'document',
        }
      },

      {
        name: 'preventOverflow',
        options: {
          // mainAxis: false, // true by default
        },
      },
    ]
  })

  // React.useEffect(() => {
  //   if (!popperNode.current) return
  //   const observer = new MutationObserver(mutations => {
  //     console.log('uukekekekek')
  //     // const timeout = setInterval(() => {
  //     //   // update()
  //     //   // update()
  //     //   console.log('uuuu')
  //     //   // console.log(update)
  //     // }
  //     //   , 1000)
  //     update()
  //     console.log('u')
  //   });

  //   // configuration of the observer:
  //   // const config = { attributes: true }
  //   const config = { childList: true, subtree: true }

  //   // pass in the target node, as well as the observer options
  //   observer.observe(popperNode.current, config)

  //   // later, you can stop observing
  //   return () => observer.disconnect()

  //   // setInterval(() => {
  //   //   update()
  //   //   console.log('k')
  //   // }, 1000)
  // }, [!!popperNode.current, update])

  const toggleIsOpen = React.useCallback(() => {
    setIsOpen((curr) => !curr)
    update()
    setTimeout(() => update && update(), 100)
  }, [update])

  React.useEffect(() => {
    update()
  }, [isOpen])

  const close = React.useCallback(() => setIsOpen(false), [])
  const handleKeyPress = React.useCallback((e) => e.keyCode === keycode('Esc') && close(), [])

  useOnClickOutside(popperNode, close, true)
  useEventListener('keydown', handleKeyPress)

  const anchorProps = {
    // onClick: toggleIsOpen,
    ref: referenceNode
  }
  const menuProps = {
    isOpen,
    ref: popperNode,
    style: {
      ...style,
      zIndex: 9999
    },
  }

  const [arrowPlacement] = placement.split("-");
  const transformMap = {
    top: "rotateZ(180deg)",
    right: "rotateZ(-90deg)",
    bottom: "rotateZ(360deg)",
    left: "rotateZ(90deg)"

    // right: "rotateZ(180deg)",
    // top: "rotateZ(-90deg)",
    // left: "rotateZ(360deg)",
    // bottom: "rotateZ(90deg)"
  };

  const arrowProps = {
    ref: arrowNode,
    style: {
      ...arrowStyle,
      position: 'absolute',
      display: isOpen ? 'block': 'none',
      transform: transformMap[arrowPlacement],
      [arrowPlacement]: '100%',
      width: '1em',
      height: '1em',
    },
  }

  return [anchorProps, menuProps, arrowProps, close]
}

export default useModal
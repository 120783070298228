// Riva - Function
import { createReducer, setDraft } from 'runic/core/redux'

import coreActions from './actions'
import authenticationActions from '../authentication/actions'

const defaultState = {
  activeRcTenantList: [],
  activeRcUser: null,
  activeRcTenant: null,
  appVersion: null,
  appStatus: 'LOADING',
  locale: 'tr',
  versionUpdate: false,
  config: {
    rPlatform: 'RUNIC_PUBLIC'
  }
}

export default createReducer(defaultState, {
  [coreActions.rcrRivaSetup]: {
    success: (draft, { rcEntity, version }) => {
      setDraft(draft, {
        rcTenantList: rcEntity.result.rc_tenant_list.items,
        activeRcUserId: rcEntity.result.rc_user,
        appVersion: version,
      })
    },

    error: (draft, payload) => draft.appStatus = 'ERROR',
    // start: (draft, payload) => draft.appStatus = 'LOADING'
  },

  [coreActions.bootstrap]: {
    success: (draft, { rcEntity, version, rcUserCount }, store) => {
      setDraft(draft, {
        activeRcUserId: rcEntity && rcEntity.result.rc_user,
        rcrVersion: version,
        rcUserCount: rcUserCount,
        appStatus: 'READY'
      })
    },

    error: (draft, payload) => draft.appStatus = 'ERROR',
    start: (draft, payload) => draft.appStatus = 'LOADING'
  },

  [coreActions.runicBootstrap]: (draft, { config }) => {
    console.log('--', config)
    draft.config = config
  },

  [coreActions.rcrRivaGetTenantList]: {
    success: (draft, { rcEntity }) => {
      setDraft(draft, {
        rcTenantList: rcEntity && rcEntity.result.rc_tenant_list.items
      })
    },
  },

  [authenticationActions.rcrLogin]: {
    success: (draft, { rc_user }) => {
      draft.activeRcUserId = rc_user.id
    },
  },

  [coreActions.rcrActivateRcTenant]: (draft, { id }) => draft.activeRcTenantId = parseInt(id),

  [coreActions.rcrAppVersionUpdate]: (draft, { kind }) => draft.versionUpdate = kind,

  [coreActions.rcrRivaTenantSetup]: {
    start: (draft, { rcTenantId }) => draft.activeRcTenantId = rcTenantId,

    success: (draft, { rc_tenant }) => draft.activeRcTenant = rc_tenant
  },
})

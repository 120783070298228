import React from 'react'

// Vendor
import * as changeCase from 'volcano/util/text'

// Volcano
import { Flex, Box } from 'volcano'
import Icon from 'volcano/components/Icon'
import Text from 'volcano/components/Text'
import { ContentHeader } from 'volcano/components/Content'

// RunicAura
import { Link, useMatch, useLocation } from 'runic-aura/router'
import ModelDetailCard from './ModelDetailCard'

// Runic
import { useDetailView } from 'runic/hooks/model'

const ModelDetailView = ({
  backUrl,
  modelName,
  modelId,
  fieldName,
  fieldPath
}) => {
  const dvInstance = useDetailView({ modelName, value: modelId, fieldName, fieldPath })
  if (!dvInstance.entity || !dvInstance.view) return null

  return (
    <>
      <Flex>
        <Box>
          {/* {backUrl && <Link to={backUrl}><Icon name='arrow-left' color='primary' />Geri Dön</Link>} */}
          <ContentHeader>
            <Text fontWeight='bold' textCase='title' id={`label.${changeCase.snake(dvInstance.modelData.name)}`} />
          </ContentHeader>

          <Box mb={2} />
        </Box>
      </Flex>

      <ModelDetailCard entity={dvInstance.entity} modelData={dvInstance.modelData} view={dvInstance.view} noWrapper={true} fieldKind='primary' />
    </>
  )
}

export default ModelDetailView
import React from 'react'

// Vendor
import * as yup from 'yup'
import dotProp from 'dot-prop'
import { useFormState } from 'react-final-form'

// Reverb
import { TextField, CheckboxField , DropdownField, FileField} from 'runic-aura/components/RunicField'
import Text from 'volcano/components/Text'
import ModelTypeahead from './ModelTypeahead'


const ModelFormField = ({
  field,
  index
}) => {
  if (field.connected) {
    return <ConnectedModelFormField field={field} index={index}/>
  } else {
    return <DefaultModelFormField field={field} index={index}/>
  }
}

const DefaultModelFormField = ({
  field,
  index
}) => {
  let schema
  if (!field.optional) schema = yup.string().required()

  const focus = index === 0

  if (field.fieldKind == 'TYPEAHEAD') {
    return (
      <ModelTypeahead modelName={field.model} type={field.kind} name={field.name} label={<Text textCase='title' id={field.label} />} schema={schema} required={!field.optional} focus={focus} hiddenIfValue={field.hiddenIfProvided} />
    )
  } else if (field.fieldKind == 'dropdown') {
    return (
      <DropdownField name={field.name} label={<Text textCase='title' id={field.label} />} schema={schema} required={!field.optional} {...field.componentProps} />
    )
  } else if (field.fieldKind == 'BOOL') {
    return (
      <CheckboxField name={field.name} label={field.label}/>
    )
  } else if (field.fieldKind == 'FILE') {
    return (
      <FileField name={field.name} label={field.label} {...field.params}/>
    )
  }

  return (
    <TextField type={field.kind} name={field.name} label={<Text textCase='title' id={field.label} />} schema={schema} required={!field.optional} focus={focus} />
  )
  // <TextField name="email" label='E-Posta' tabIndex={1} schema={yup.string().min(4).required()} required focus />
  //     <TextField name="password" label='Şifre' type='password' tabIndex={2} required />
  //     <CheckboxField name='rememberMe' label='Beni hatırla' tabIndex={3} />
}

const ConnectedModelFormField = ({
  field,
  index
}) => {
  let schema
  if (!field.optional) schema = yup.string().required()

  const formState = useFormState({
    subscription: {
      values: true
    }
  })

  const values = formState.values

  let isReady = !field.dependsOn
  field.dependsOn?.forEach(name => {
    if (
      values[name] && values[name] !== null && values[name] !== undefined
    ) {
      isReady = true
    }
  })

  if (!isReady) return null

  const params = {}
  if (field.params) {
    Object.entries(field.params).forEach(([key, value]) => {
      if (value.includes?.('@@r.form.')) {
        const keys = value.replace('@@r.form.', '')
        const resolvedValue = dotProp.get(values, keys)
        params[key] = resolvedValue
      } else if (value.includes?.('@@r.')) {

      } else {
        params[key] = value
      }
    })
  }

  const focus = index === 0

  if (field.fieldKind == 'TYPEAHEAD') {
    return (
      <ModelTypeahead modelName={field.model} type={field.kind} name={field.name} label={<Text textCase='title' id={field.label} />} schema={schema} required={!field.optional} focus={focus} params={params} />
    )
  } else if (field.fieldKind == 'dropdown') {
    return (
      <DropdownField name={field.name} label={<Text textCase='title' id={field.label} />} schema={schema} required={!field.optional} {...field.componentProps} />
    )
  }

  if (field.fieldKind == 'BOOL') return (
    <CheckboxField name={field.name} label={field.label}/>
  )

  return (
    <TextField type={field.kind} name={field.name} label={<Text textCase='title' id={field.label} />} schema={schema} required={!field.optional} focus={focus} />
  )
  // <TextField name="email" label='E-Posta' tabIndex={1} schema={yup.string().min(4).required()} required focus />
  //     <TextField name="password" label='Şifre' type='password' tabIndex={2} required />
  //     <CheckboxField name='rememberMe' label='Beni hatırla' tabIndex={3} />
}

export default ModelFormField
import React from 'react'

// Volcano
import Box from './Box'


const Card = ({ p = 3, bg = 'light9', borderRadius = 2, selected, variant, children, ...rest }) => {

  let _variant = variant
  if (!_variant && selected) _variant = 'selected'

  return (
    <Box
      p={p}
      bg={bg}
      __css={{
        boxShadow: "0px 0px 40px rgba(0,0,0,0.1)",
        borderRadius
      }}
      variant={_variant}
      variants={{
        selected: {
          border: '1px solid #FFF',
          borderColor: 'primary'
        }
      }}
      {...rest}>
      {children}
    </Box>
  )
}

export default Card